export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';

export const FETCH_COUNTRY = 'FETCH_COUNTRY';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_ERROR = 'FETCH_COUNTRY_ERROR';

export const CREATE_CUSTOMERS = 'CREATE_CUSTOMERS';
export const CREATE_CUSTOMERS_SUCCESS = 'CREATE_CUSTOMERS_SUCCESS';
export const CREATE_CUSTOMERS_ERROR = 'CREATE_CUSTOMERS_ERROR';

export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS';
export const UPDATE_CUSTOMERS_SUCCESS = 'UPDATE_CUSTOMERS_SUCCESS';
export const UPDATE_CUSTOMERS_ERROR = 'UPDATE_CUSTOMERS_ERROR';

export const UPDATE_CUSTOMERS_STATUS = 'UPDATE_CUSTOMERS_STATUS';
export const UPDATE_CUSTOMERS_STATUS_SUCCESS = 'UPDATE_CUSTOMERS_STATUS_SUCCESS';
export const UPDATE_CUSTOMERS_STATUS_ERROR = 'UPDATE_CUSTOMERS_STATUS_ERROR';

export const DELETE_CUSTOMERS = 'DELETE_CUSTOMERS';
export const DELETE_CUSTOMERS_SUCCESS = 'DELETE_CUSTOMERS_SUCCESS';
export const DELETE_CUSTOMERS_ERROR = 'DELETE_CUSTOMERS_ERROR';

export const VIEW_CUSTOMERS = 'VIEW_CUSTOMERS';
export const VIEW_CUSTOMERS_SUCCESS = 'VIEW_CUSTOMERS_SUCCESS';
export const VIEW_CUSTOMERS_ERROR = 'VIEW_CUSTOMERS_ERROR';

export const CREATE_CUSTOMERS_CONTACT = 'CREATE_CUSTOMERS_CONTACT';
export const CREATE_CUSTOMERS_CONTACT_SUCCESS = 'CREATE_CUSTOMERS_CONTACT_SUCCESS';
export const CREATE_CUSTOMERS_CONTACT_ERROR = 'CREATE_CUSTOMERS_CONTACT_ERROR';

export const UPDATE_CUSTOMERS_CONTACT = 'UPDATE_CUSTOMERS_CONTACT';
export const UPDATE_CUSTOMERS_CONTACT_SUCCESS = 'UPDATE_CUSTOMERS_CONTACT_SUCCESS';
export const UPDATE_CUSTOMERS_CONTACT_ERROR = 'UPDATE_CUSTOMERS_CONTACT_ERROR';

export const DELETE_CUSTOMERS_CONTACT = 'DELETE_CUSTOMERS_CONTACT';
export const DELETE_CUSTOMERS_CONTACT_SUCCESS = 'DELETE_CUSTOMERS_CONTACT_SUCCESS';
export const DELETE_CUSTOMERS_CONTACT_ERROR = 'DELETE_CUSTOMERS_CONTACT_ERROR';

export const CREATE_CUSTOMERS_DOCUMENT = 'CREATE_CUSTOMERS_DOCUMENT';
export const CREATE_CUSTOMERS_DOCUMENT_SUCCESS = 'CREATE_CUSTOMERS_DOCUMENT_SUCCESS';
export const CREATE_CUSTOMERS_DOCUMENT_ERROR = 'CREATE_CUSTOMERS_DOCUMENT_ERROR';

export const UPDATE_CUSTOMERS_DOCUMENT = 'UPDATE_CUSTOMERS_DOCUMENT';
export const UPDATE_CUSTOMERS_DOCUMENT_SUCCESS = 'UPDATE_CUSTOMERS_DOCUMENT_SUCCESS';
export const UPDATE_CUSTOMERS_DOCUMENT_ERROR = 'UPDATE_CUSTOMERS_DOCUMENT_ERROR';

export const DELETE_CUSTOMERS_DOCUMENT = 'DELETE_CUSTOMERS_DOCUMENT';
export const DELETE_CUSTOMERS_DOCUMENT_SUCCESS = 'DELETE_CUSTOMERS_DOCUMENT_SUCCESS';
export const DELETE_CUSTOMERS_DOCUMENT_ERROR = 'DELETE_CUSTOMERS_DOCUMENT_ERROR';

export const CREATEBTN_CUSTOMERS = 'CREATEBTN_CUSTOMERS';
export const CREATEBTN_CUSTOMERS_SUCCESS = 'CREATEBTN_CUSTOMERS_SUCCESS';
export const CREATEBTN_CUSTOMERS_ERROR = 'CREATEBTN_CUSTOMERS_ERROR';

export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS';
export const SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS';
export const SEARCH_CUSTOMERS_ERROR = 'SEARCH_CUSTOMERS_ERROR';

export const CUSTOMER_UPLOADED_DOC_DELETE_INIT = "CUSTOMER_UPLOADED_DOC_DELETE_INIT"
export const CUSTOMER_UPLOADED_DOC_DELETE_SUCCESS = "CUSTOMER_UPLOADED_DOC_DELETE_SUCCESS"
export const CUSTOMER_UPLOADED_DOC_DELETE_ERROR = "CUSTOMER_UPLOADED_DOC_DELETE_ERROR"

export const VIEW_EXPIRY_CUSTOMERS = 'VIEW_EXPIRY_CUSTOMERS';
export const VIEW_EXPIRY_CUSTOMERS_SUCCESS = 'VIEW_EXPIRY_CUSTOMERS_SUCCESS';
export const VIEW_EXPIRY_CUSTOMERS_ERROR = 'VIEW_EXPIRY_CUSTOMERS_ERROR';

export const FETCH_EXPIRY_CUSTOMERS = 'FETCH_EXPIRY_CUSTOMERS';
export const FETCH_EXPIRY_CUSTOMERS_SUCCESS = 'FETCH_EXPIRY_CUSTOMERS_SUCCESS';
export const FETCH_EXPIRY_CUSTOMERS_ERROR = 'FETCH_EXPIRY_CUSTOMERS_ERROR';

export const CREATEUPDATE_EXPIRY_CUSTOMERS = 'CREATEUPDATE_EXPIRY_CUSTOMERS';
export const CREATEUPDATE_EXPIRY_CUSTOMERS_SUCCESS = 'CREATEUPDATE_EXPIRY_CUSTOMERS_SUCCESS';
export const CREATEUPDATE_EXPIRY_CUSTOMERS_ERROR = 'CREATEUPDATE_EXPIRY_CUSTOMERS_ERROR';

export const STATUS_CUSTOMERS = 'STATUS_CUSTOMERS';
export const STATUS_CUSTOMERS_SUCCESS = 'STATUS_CUSTOMERS_SUCCESS';
export const STATUS_CUSTOMERS_ERROR = 'STATUS_CUSTOMERS_ERROR';
