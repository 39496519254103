export const FETCH_TRACKINGLIST_INIT = 'FETCH_TRACKINGLIST_INIT';
export const FETCH_TRACKINGLIST_SUCCESS = 'FETCH_TRACKINGLIST_SUCCESS';
export const FETCH_TRACKINGLIST_ERROR = 'FETCH_TRACKINGLIST_ERROR';

export const FETCH_TRACKINGONE_INIT = 'FETCH_TRACKINGONE_INIT';
export const FETCH_TRACKINGONE_SUCCESS = 'FETCH_TRACKINGONE_SUCCESS';
export const FETCH_TRACKINGONE_ERROR = 'FETCH_TRACKINGONE_ERROR';

export const FETCH_ASSIGNEDROUTE_INIT = 'FETCH_ASSIGNEDROUTE_INIT';
export const FETCH_ASSIGNEDROUTE_SUCCESS = 'FETCH_ASSIGNEDROUTE_SUCCESS';
export const FETCH_ASSIGNEDROUTE_ERROR = 'FETCH_ASSIGNEDROUTE_ERROR';

export const FETCH_LIVEROUTE_INIT = 'FETCH_LIVEROUTE_INIT';
export const FETCH_LIVEROUTE_SUCCESS = 'FETCH_LIVEROUTE_SUCCESS';
export const FETCH_LIVEROUTE_ERROR = 'FETCH_LIVEROUTE_ERROR';