import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/ProfileConstants";
import ProfileService from "../../services/ProfileService";

export function* fetchEmployeeChangepasswordSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchEmployeeChangePassword, payload);
        yield put({ type: constant.EMPLOYEE_CHANGE_PASSWORD_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.EMPLOYEE_CHANGE_PASSWORD_ERROR, error })
    }
}
export function* fetchBranchSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchBranch, payload);
        yield put({ type: constant.FETCH_BRANCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_BRANCH_ERROR, error })
    }
}
export function* createBranchSaga(payload) {
    try {
        const response = yield call(ProfileService.CreateBranch, payload.data);
        yield put({ type: constant.CREATE_BRANCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_BRANCH_ERROR, error })
    }
}
export function* fetchGroupSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchGroup, payload);
        yield put({ type: constant.FETCH_GROUP_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_GROUP_ERROR, error })
    }
}
export function* createGroupSaga(payload) {
    try {
        const response = yield call(ProfileService.CreateGroup, payload.data);
        yield put({ type: constant.CREATE_GROUP_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_GROUP_ERROR, error })
    }
}
export function* fetchDepartmentSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchDepartment, payload);
        yield put({ type: constant.FETCH_DEPARTMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_DEPARTMENT_ERROR, error })
    }
}
export function* createDepartmentSaga(payload) {
    try {
        const response = yield call(ProfileService.CreateDepartment, payload.data);
        yield put({ type: constant.CREATE_DEPARTMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_DEPARTMENT_ERROR, error })
    }
}
export function* fetchTaxesSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchTaxes, payload);
        yield put({ type: constant.FETCH_TAXES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_TAXES_ERROR, error })
    }
}
export function* createTaxesSaga(payload) {
    try {
        const response = yield call(ProfileService.CreateTaxes, payload.data);
        yield put({ type: constant.CREATE_TAXES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_TAXES_ERROR, error })
    }
}

export function* fetchAdminSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchAdmin, payload);
        yield put({ type: constant.ADMIN_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.ADMIN_FETCH_ERROR, error })
    }
}

export function* adminChangepasswordSaga(payload) {
    try {
        const response = yield call(ProfileService.AdminChangePassword, payload);
        yield put({ type: constant.ADMIN_CHANGE_PASSWORD_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.ADMIN_CHANGE_PASSWORD_ERROR, error })
    }
}

// branch group department

export function* updateBranchSaga(payload) {
    try {
        const response = yield call(ProfileService.UpdateBranch, payload.data);
        yield put({ type: constant.UPDATE_BRANCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_BRANCH_ERROR, error })
    }
}
export function* updateDepartmentSaga(payload) {
    try {
        const response = yield call(ProfileService.UpdateDepartment, payload.data);
        yield put({ type: constant.UPDATE_DEPARTMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_DEPARTMENT_ERROR, error })
    }
}
export function* updateGroupSaga(payload) {
    try {
        const response = yield call(ProfileService.UpdateGroup, payload.data);
        yield put({ type: constant.UPDATE_GROUP_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_GROUP_ERROR, error })
    }
}
export function* deleteBranchSaga(payload) {
    try {
        const response = yield call(ProfileService.DeleteBranch, payload);
        yield put({ type: constant.DELETE_BRANCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_BRANCH_ERROR, error })
    }
}
export function* deleteDepartmentSaga(payload) {
    try {
        const response = yield call(ProfileService.DeleteDepartment, payload);
        yield put({ type: constant.DELETE_DEPARTMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_DEPARTMENT_ERROR, error })
    }
}
export function* deleteGroupSaga(payload) {
    try {
        const response = yield call(ProfileService.DeleteGroup, payload);
        yield put({ type: constant.DELETE_GROUP_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_GROUP_ERROR, error })
    }
}

// Customer POD sagas

export function* CustomerPODViewSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerPODView, payload);
        yield put({ type: constant.CUSTOMERPOD_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERPOD_FETCH_ERROR, error })
    }
}

export function* CustomerPODCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerPODCreate, payload);
        yield put({ type: constant.CUSTOMERPOD_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERPOD_CREATE_ERROR, error })
    }
}

export function* CustomerPODUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerPODUpdate, payload);
        yield put({ type: constant.CUSTOMERPOD_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERPOD_UPDATE_ERROR, error })
    }
}

export function* CustomerPODDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerPODDelete, payload);
        yield put({ type: constant.CUSTOMERPOD_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERPOD_DELETE_ERROR, error })
    }
}

// Customer type sagas

export function* CustomerTypeViewSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerTypeView, payload);
        yield put({ type: constant.CUSTOMERTYPE_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERTYPE_FETCH_ERROR, error })
    }
}

export function* CustomerTypeCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerTypeCreate, payload);
        yield put({ type: constant.CUSTOMERTYPE_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERTYPE_CREATE_ERROR, error })
    }
}

export function* CustomerTypeUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerTypeUpdate, payload);
        yield put({ type: constant.CUSTOMERTYPE_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERTYPE_UPDATE_ERROR, error })
    }
}

export function* CustomerTypeDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCustomerTypeDelete, payload);
        yield put({ type: constant.CUSTOMERTYPE_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMERTYPE_DELETE_ERROR, error })
    }
}

// vendor type sagas

export function* VendorTypeViewSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchVendorTypeView, payload);
        yield put({ type: constant.VENDORTYPE_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VENDORTYPE_FETCH_ERROR, error })
    }
}

export function* VendorTypeCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchVendorTypeCreate, payload);
        yield put({ type: constant.VENDORTYPE_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VENDORTYPE_CREATE_ERROR, error })
    }
}

export function* VendorTypeUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchVendorTypeUpdate, payload);
        yield put({ type: constant.VENDORTYPE_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VENDORTYPE_UPDATE_ERROR, error })
    }
}

export function* VendorTypeDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchVendorTypeDelete, payload);
        yield put({ type: constant.VENDORTYPE_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VENDORTYPE_DELETE_ERROR, error })
    }
}

// document type sagas

export function* DocumentTypeViewSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchDocumentTypeView, payload);
        yield put({ type: constant.DOCUMENTTYPE_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DOCUMENTTYPE_FETCH_ERROR, error })
    }
}

export function* DocumentTypeCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchDocumentTypeCreate, payload);
        yield put({ type: constant.DOCUMENTTYPE_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DOCUMENTTYPE_CREATE_ERROR, error })
    }
}

export function* DocumentTypeUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchDocumentTypeUpdate, payload);
        yield put({ type: constant.DOCUMENTTYPE_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DOCUMENTTYPE_UPDATE_ERROR, error })
    }
}

export function* DocumentTypeDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchDocumentTypeDelete, payload);
        yield put({ type: constant.DOCUMENTTYPE_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DOCUMENTTYPE_DELETE_ERROR, error })
    }
}

// load type sagas

export function* LoadTypeViewSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchLoadTypeView, payload);
        yield put({ type: constant.LOADTYPE_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.LOADTYPE_FETCH_ERROR, error })
    }
}

export function* LoadTypeCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchLoadTypeCreate, payload);
        yield put({ type: constant.LOADTYPE_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.LOADTYPE_CREATE_ERROR, error })
    }
}

export function* LoadTypeUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchLoadTypeUpdate, payload);
        yield put({ type: constant.LOADTYPE_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.LOADTYPE_UPDATE_ERROR, error })
    }
}

export function* LoadTypeDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchLoadTypeDelete, payload);
        yield put({ type: constant.LOADTYPE_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.LOADTYPE_DELETE_ERROR, error })
    }
}

// package type sagas

export function* PackageTypeViewSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchPackageTypeView, payload);
        yield put({ type: constant.PACKAGETYPE_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.PACKAGETYPE_FETCH_ERROR, error })
    }
}

export function* PackageTypeCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchPackageTypeCreate, payload);
        yield put({ type: constant.PACKAGETYPE_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.PACKAGETYPE_CREATE_ERROR, error })
    }
}
export function* PackageTypeUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchPackageTypeUpdate, payload);
        yield put({ type: constant.PACKAGETYPE_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.PACKAGETYPE_UPDATE_ERROR, error })
    }
}

export function* PackageTypeDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchPackageTypeDelete, payload);
        yield put({ type: constant.PACKAGETYPE_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.PACKAGETYPE_DELETE_ERROR, error })
    }
}

export function* profileUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.ProfileUpdate, payload);
        yield put({ type: constant.PROFILE_EDIT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.PROFILE_EDIT_ERROR, error })
    }
}

export function* ProfileViewSaga(payload) {
    try {
        const response = yield call(ProfileService.ProfileView, payload);
        yield put({ type: constant.PROFILE_VIEW_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.PROFILE_VIEW_ERROR, error })
    }
}

//COMMODITY
export function* CommodityGetSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCommodityGet, payload);
        yield put({ type: constant.COMMODITY_FETCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.COMMODITY_FETCH_ERROR, error })
    }
}
export function* CommodityCreateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCommodityCreate, payload);
        yield put({ type: constant.COMMODITY_CREATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.COMMODITY_CREATE_ERROR, error })
    }
}
export function* CommodityUpdateSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCommodityUpdate, payload);
        yield put({ type: constant.COMMODITY_UPDATE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.COMMODITY_UPDATE_ERROR, error })
    }
}
export function* CommodityTypeDeleteSaga(payload) {
    try {
        const response = yield call(ProfileService.FetchCommodityDelete, payload);
        yield put({ type: constant.COMMODITY_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.COMMODITY_DELETE_ERROR, error })
    }
}
export default function* ProfileSaga() {
    yield takeLatest(constant.EMPLOYEE_CHANGE_PASSWORD, fetchEmployeeChangepasswordSaga);
    yield takeLatest(constant.FETCH_BRANCH, fetchBranchSaga);
    yield takeLatest(constant.FETCH_DEPARTMENT, fetchDepartmentSaga);
    yield takeLatest(constant.FETCH_TAXES, fetchTaxesSaga);
    yield takeLatest(constant.PROFILE_VIEW_INIT, ProfileViewSaga);
    yield takeLatest(constant.FETCH_GROUP, fetchGroupSaga);
    yield takeLatest(constant.CREATE_BRANCH, createBranchSaga);
    yield takeLatest(constant.CREATE_DEPARTMENT, createDepartmentSaga);
    yield takeLatest(constant.CREATE_TAXES, createTaxesSaga);
    yield takeLatest(constant.CREATE_GROUP, createGroupSaga);
    yield takeLatest(constant.ADMIN_FETCH, fetchAdminSaga);
    yield takeLatest(constant.ADMIN_CHANGE_PASSWORD, adminChangepasswordSaga);

    // group department branch

    yield takeLatest(constant.UPDATE_BRANCH, updateBranchSaga);
    yield takeLatest(constant.UPDATE_DEPARTMENT, updateDepartmentSaga);
    yield takeLatest(constant.UPDATE_GROUP, updateGroupSaga);
    yield takeLatest(constant.DELETE_BRANCH, deleteBranchSaga);
    yield takeLatest(constant.DELETE_DEPARTMENT, deleteDepartmentSaga);
    yield takeLatest(constant.DELETE_GROUP, deleteGroupSaga);

    // customer pod
    yield takeLatest(constant.CUSTOMERPOD_FETCH, CustomerPODViewSaga);
    yield takeLatest(constant.CUSTOMERPOD_CREATE, CustomerPODCreateSaga);
    yield takeLatest(constant.CUSTOMERPOD_UPDATE, CustomerPODUpdateSaga);
    yield takeLatest(constant.CUSTOMERPOD_DELETE, CustomerPODDeleteSaga);

    // customer type
    yield takeLatest(constant.CUSTOMERTYPE_FETCH, CustomerTypeViewSaga);
    yield takeLatest(constant.CUSTOMERTYPE_CREATE, CustomerTypeCreateSaga);
    yield takeLatest(constant.CUSTOMERTYPE_UPDATE, CustomerTypeUpdateSaga);
    yield takeLatest(constant.CUSTOMERTYPE_DELETE, CustomerTypeDeleteSaga);

    // vendor type
    yield takeLatest(constant.VENDORTYPE_FETCH, VendorTypeViewSaga);
    yield takeLatest(constant.VENDORTYPE_CREATE, VendorTypeCreateSaga);
    yield takeLatest(constant.VENDORTYPE_UPDATE, VendorTypeUpdateSaga);
    yield takeLatest(constant.VENDORTYPE_DELETE, VendorTypeDeleteSaga);

    // document type
    yield takeLatest(constant.DOCUMENTTYPE_FETCH, DocumentTypeViewSaga);
    yield takeLatest(constant.DOCUMENTTYPE_CREATE, DocumentTypeCreateSaga);
    yield takeLatest(constant.DOCUMENTTYPE_UPDATE, DocumentTypeUpdateSaga);
    yield takeLatest(constant.DOCUMENTTYPE_DELETE, DocumentTypeDeleteSaga);

    // load type
    yield takeLatest(constant.LOADTYPE_FETCH, LoadTypeViewSaga);
    yield takeLatest(constant.LOADTYPE_CREATE, LoadTypeCreateSaga);
    yield takeLatest(constant.LOADTYPE_UPDATE, LoadTypeUpdateSaga);
    yield takeLatest(constant.LOADTYPE_DELETE, LoadTypeDeleteSaga);

    // package type
    yield takeLatest(constant.PACKAGETYPE_FETCH, PackageTypeViewSaga);
    yield takeLatest(constant.PACKAGETYPE_CREATE, PackageTypeCreateSaga);
    yield takeLatest(constant.PACKAGETYPE_UPDATE, PackageTypeUpdateSaga);
    yield takeLatest(constant.PROFILE_EDIT_INIT, profileUpdateSaga);
    yield takeLatest(constant.PACKAGETYPE_DELETE, PackageTypeDeleteSaga);

    //COMMODITY
    yield takeLatest(constant.COMMODITY_FETCH, CommodityGetSaga);
    yield takeLatest(constant.COMMODITY_CREATE, CommodityCreateSaga);
    yield takeLatest(constant.COMMODITY_UPDATE, CommodityUpdateSaga);
    // yield takeLatest(constant.COMNODITY_UPDATE, PackageTypeUpdateSaga);
    yield takeLatest(constant.COMMODITY_DELETE, CommodityTypeDeleteSaga);
}