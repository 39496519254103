export const FETCH_ESSENTIAL = 'FETCH_ESSENTIAL';
export const FETCH_ESSENTIAL_SUCCESS = 'FETCH_ESSENTIAL_SUCCESS';
export const FETCH_ESSENTIAL_ERROR = 'FETCH_ESSENTIAL_ERROR';

export const FETCH_ESSENTIAL_DISPATCHORDER = 'FETCH_ESSENTIAL_DISPATCHORDER';
export const FETCH_ESSENTIAL_DISPATCHORDER_SUCCESS = 'FETCH_ESSENTIAL_DISPATCHORDER_SUCCESS';
export const FETCH_ESSENTIAL_DISPATCHORDER_ERROR = 'FETCH_ESSENTIAL_DISPATCHORDER_ERROR';



export const  FETCH_ESSENTIAL_COUNTRY = 'FETCH_ESSENTIAL_COUNTRY';
export const  FETCH_ESSENTIAL_COUNTRY_SUCCESS = 'FETCH_ESSENTIAL_COUNTRY_SUCCESS';
export const  FETCH_ESSENTIAL_COUNTRY_ERROR = 'FETCH_ESSENTIAL_COUNTRY_ERROR';


