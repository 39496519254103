import fetch from 'auth/FetchInterceptor';

const DashboardService = {}

DashboardService.Viewlatestorder = function () {
    return fetch({
        url: '/latest-order',
        method: 'get'
    })
}

DashboardService.Viewlatestemployee = function () {
    return fetch({
        url: '/live-employee',
        method: 'get'
    })
}


export default DashboardService