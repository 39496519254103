import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { AUTH_ROLE, AUTH_NAME } from 'redux/constants/Auth'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'TMS';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const API_IMAGE_URL = env.API_ENDPOINT_IMAGEVIEW
export const API_DOCUMENT_URL = env.API_ENDPOINT_DOCUMENTVIEW
export const API_EMPDOCUMENT_URL = env.API_ENDPOINT_EMPDOCUMENTVIEW
export const API_DOWNLOAD_URL = env.API_ENDPOINT_DOCUMENTDOWNLOAD
export const API_DOWNLOAD_IMAGE = env.API_ENDPOINT_IMAGE_DOWNLOAD
// export const APP_PREFIX_PATH = '/driver'; //don't set null it affect redirects
export const APP_PREFIX_PATH = '/' + localStorage.getItem(AUTH_ROLE);
export const AUTH_PREFIX_PATH = '/auth';
export const USER_ROLE = localStorage.getItem(AUTH_ROLE) === "driver" ? "Driver" : "Super Admin";
export const USER_NAME = localStorage.getItem(AUTH_NAME);
export const MAPBOX_TOKEN = "pk.eyJ1IjoiY2FwZWNvbSIsImEiOiJja3RpZjB6czAwYWl1MndxcmNoOXRjZ2ZwIn0.1N2ifoE99FRExgCUT8Myqw";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
