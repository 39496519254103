import fetch from 'auth/FetchInterceptor';


const EquipmentService = {}
EquipmentService.ServiceFetchEquipments = function () {
   
  return fetch({
      url: '/equipment_services-list',
      method: 'get',
    
  })
}

EquipmentService.ViewServiceEquipments = function (id) {
    let token = localStorage.getItem('auth_token');
    
    return fetch({
       
        url: `/equipment_services-show/${id}`,
        method: 'get',
        headers: {
            "Content-Type": 'application/json',
            "Authorization": 'Bearer ' + token, 
        },
    })
}


  EquipmentService.ServiceCreateEquipments = function (data) {
   
   
    return fetch({
      url: '/equipment_services-create',
      method: 'post',
      data: data
    })
  }

export default EquipmentService