import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import { reducer as formReducer } from 'redux-form';
import CustomerReducer from './CustomerReducer';
import EmployeeReducer from './EmployeeReducer';
import EquipmentReducer from './EquipmentReducer';
import ServiceReducer from './ServiceReducer';
import VendorReducer from './VendorReducer';
import EssentialReducer from './EssentialReducer';
import CompanyReducer from './CompanyReducer';
import OrderReducer from './OrderReducer';
import QuoteReducer from './QuoteReducer';
import DispatchReducer from './DispatchReducer';
import RevenueReducer from './RevenueReducer';
import ExpenseReducer from './ExpenseReducer';
import DashboardReducer from './DashboardReducer';
import ProfileReducer from './ProfileReducer';
import ShipmentReducer from './ShipmentReducer';
import LivetrackingReducer from './LivetrackingReducer';
import LocationReducer from './LocationReducer';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    customer: CustomerReducer,
    employee: EmployeeReducer,
    equipment: EquipmentReducer,
    service: ServiceReducer,
    vendor: VendorReducer,
    essential: EssentialReducer,
    company: CompanyReducer,
    form: formReducer,
    order: OrderReducer,
    quote: QuoteReducer,
    dispatches: DispatchReducer,
    revenue: RevenueReducer,
    expense: ExpenseReducer,
    dashboard: DashboardReducer,
    profile: ProfileReducer,
    shipment: ShipmentReducer,
    livetracking: LivetrackingReducer,
    location: LocationReducer,
});

export default reducers;