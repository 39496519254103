export const FETCH_LOCATION_INIT = 'FETCH_LOCATION_INIT';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_ERROR = 'FETCH_LOCATION_ERROR';

export const CREATE_LOCATION_INIT = 'CREATE_LOCATION_INIT';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';

export const UPDATE_LOCATION_INIT = 'UPDATE_LOCATION_INIT';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';

export const VIEW_LOCATION_INIT = 'VIEW_LOCATION_INIT';
export const VIEW_LOCATION_SUCCESS = 'VIEW_LOCATION_SUCCESS';
export const VIEW_LOCATION_ERROR = 'VIEW_LOCATION_ERROR';

export const DELETE_LOCATION_INIT = 'DELETE_LOCATION_INIT';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';

export const LOCATION_CHECK_INIT = 'LOCATION_CHECK_INIT';
export const LOCATION_CHECK_SUCCESS = 'LOCATION_CHECK_SUCCESS';
export const LOCATION_CHECK_ERROR = 'LOCATION_CHECK_ERROR';

export const LOCATION_STATUS_INIT = 'LOCATION_STATUS_INIT';
export const LOCATION_STATUS_SUCCESS = 'LOCATION_STATUS_SUCCESS';
export const LOCATION_STATUS_ERROR = 'LOCATION_STATUS_ERROR';

// export const MOVE_TO_DISPATCH = 'MOVE_TO_DISPATCH';
// export const MOVE_TO_DISPATCH_SUCCESS = 'MOVE_TO_DISPATCH_SUCCESS';
// export const MOVE_TO_DISPATCH_ERROR = 'MOVE_TO_DISPATCH_ERROR';

// export const FETCH_ORDERS_COUNT_INIT = 'FETCH_ORDERS_COUNT_INIT';
// export const FETCH_ORDERS_COUNT_SUCCESS = 'FETCH_ORDERS_COUNT_SUCCESS';
// export const FETCH_ORDERS_COUNT_ERROR = 'FETCH_ORDERS_COUNT_ERROR';