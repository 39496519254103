import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/CustomerConstants";
import CustomerService from "../../services/CustomerService";

export function* fetchCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.FetchCustomers, payload);
        yield put({ type: constant.FETCH_CUSTOMERS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_CUSTOMERS_ERROR, error })
    }
}
export function* fetchCountrySaga(payload) {
    try {
        const response = yield call(CustomerService.FetchCountry, payload.data);
        yield put({ type: constant.FETCH_COUNTRY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_COUNTRY_ERROR, error })
    }
}
export function* createCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.CreateCustomers, payload.data);
        yield put({ type: constant.CREATE_CUSTOMERS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_CUSTOMERS_ERROR, error })
    }
}

export function* updateCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.UpdateCustomers, payload.data);
        yield put({ type: constant.UPDATE_CUSTOMERS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_CUSTOMERS_ERROR, error })
    }
}

export function* Updatacustomerstatus(payload) {
    try{
        const response = yield  call(CustomerService.UpdateCustomersStatus , payload.data);
        yield put({type : constant.UPDATE_CUSTOMERS_STATUS_SUCCESS , response})
    }catch(error) {
        yield put ({type: constant.UPDATE_CUSTOMERS_STATUS_ERROR , error})
    }
}

export function* deleteCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.DeleteCustomers, payload);
        yield put({ type: constant.DELETE_CUSTOMERS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_CUSTOMERS_ERROR, error })
    }
}

export function* deleteCustomerUploadedDocSaga(payload) {
    try {
        const response = yield call(CustomerService.DeleteCustomerUploadedDoc, payload);
        yield put({ type: constant.CUSTOMER_UPLOADED_DOC_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CUSTOMER_UPLOADED_DOC_DELETE_ERROR, error })
    }
}

export function* viewCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.ViewCustomers, payload);
        yield put({ type: constant.VIEW_CUSTOMERS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_CUSTOMERS_ERROR, error })
    }
}
export function* addCustomerContactSaga(payload) {
    try {
        const response = yield call(CustomerService.CreateCustomercontact, payload.data);
        yield put({ type: constant.CREATE_CUSTOMERS_CONTACT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_CUSTOMERS_CONTACT_ERROR, error })
    }
}
export function* updateCustomerContactSaga(payload) {
    try {
        const response = yield call(CustomerService.UpdateCustomercontact, payload.data);
        yield put({ type: constant.UPDATE_CUSTOMERS_CONTACT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_CUSTOMERS_CONTACT_ERROR, error })
    }
}
export function* addCustomerDocumentSaga(payload) {
    try {
        const response = yield call(CustomerService.CreateCustomerdocument, payload.data);
        yield put({ type: constant.CREATE_CUSTOMERS_DOCUMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_CUSTOMERS_DOCUMENT_ERROR, error })
    }
}
export function* deleteCustomerContactSaga(payload) {
    try {
        const response = yield call(CustomerService.DeleteCustomercontact, payload);
        yield put({ type: constant.DELETE_CUSTOMERS_CONTACT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_CUSTOMERS_CONTACT_ERROR, error })
    }
}
export function* updateCustomerDocumentSaga(payload) {
    try {
        const response = yield call(CustomerService.UpdateCustomerdocument, payload.data);
        yield put({ type: constant.UPDATE_CUSTOMERS_DOCUMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_CUSTOMERS_DOCUMENT_ERROR, error })
    }
}
export function* deleteCustomerDocumentSaga(payload) {
    try {
        const response = yield call(CustomerService.DeleteCustomerdocument, payload);
        yield put({ type: constant.DELETE_CUSTOMERS_DOCUMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_CUSTOMERS_DOCUMENT_ERROR, error })
    }
}
export function* FetchCustomerBtnSaga() {
    try {
        const response = yield call(CustomerService.CreateBtnCustomers);
        yield put({ type: constant.CREATEBTN_CUSTOMERS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATEBTN_CUSTOMERS_ERROR, error });
    }
}
export function* searchCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.SearchCustomers, payload);
        yield put({ type: constant.SEARCH_CUSTOMERS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.SEARCH_CUSTOMERS_ERROR, error })
    }
}

export function* FetchExpiryCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.FetchExpiryCustomers, payload);
        yield put({ type: constant.FETCH_EXPIRY_CUSTOMERS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.FETCH_EXPIRY_CUSTOMERS_ERROR, error });
    }
}
export function* CreateUpdateExpiryCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.CreateUpdateExpiryCustomers, payload.data);
        yield put({ type: constant.CREATEUPDATE_EXPIRY_CUSTOMERS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATEUPDATE_EXPIRY_CUSTOMERS_ERROR, error });
    }
}
export function* ViewExpiryCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.ViewExpiryCustomers, payload.id);
        yield put({ type: constant.VIEW_EXPIRY_CUSTOMERS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.VIEW_EXPIRY_CUSTOMERS_ERROR, error });
    }
}
export function* StatusCustomerSaga(payload) {
    try {
        const response = yield call(CustomerService.StatusCustomers, payload.data);
        yield put({ type: constant.STATUS_CUSTOMERS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.STATUS_CUSTOMERS_ERROR, error });
    }
}
export default function* CustomerSaga() {
    yield takeLatest(constant.FETCH_CUSTOMERS, fetchCustomerSaga);
    yield takeLatest(constant.FETCH_COUNTRY, fetchCountrySaga);
    yield takeLatest(constant.CREATE_CUSTOMERS, createCustomerSaga);
    yield takeLatest(constant.UPDATE_CUSTOMERS, updateCustomerSaga);
    yield takeLatest(constant.DELETE_CUSTOMERS, deleteCustomerSaga);
    yield takeLatest(constant.VIEW_CUSTOMERS, viewCustomerSaga);
    yield takeLatest(constant.CREATE_CUSTOMERS_CONTACT, addCustomerContactSaga);
    yield takeLatest(constant.UPDATE_CUSTOMERS_CONTACT, updateCustomerContactSaga);
    yield takeLatest(constant.CREATE_CUSTOMERS_DOCUMENT, addCustomerDocumentSaga);
    yield takeLatest(constant.DELETE_CUSTOMERS_CONTACT, deleteCustomerContactSaga);
    yield takeLatest(constant.CUSTOMER_UPLOADED_DOC_DELETE_INIT, deleteCustomerUploadedDocSaga);
    yield takeLatest(constant.UPDATE_CUSTOMERS_DOCUMENT, updateCustomerDocumentSaga);
    yield takeLatest(constant.DELETE_CUSTOMERS_DOCUMENT, deleteCustomerDocumentSaga);
    yield takeLatest(constant.FETCH_CUSTOMERS, FetchCustomerBtnSaga);
    yield takeLatest(constant.SEARCH_CUSTOMERS, searchCustomerSaga);
    yield takeLatest(constant.FETCH_EXPIRY_CUSTOMERS, FetchExpiryCustomerSaga);
    yield takeLatest(constant.CREATEUPDATE_EXPIRY_CUSTOMERS, CreateUpdateExpiryCustomerSaga);
    yield takeLatest(constant.VIEW_EXPIRY_CUSTOMERS, ViewExpiryCustomerSaga);
    yield takeLatest(constant.STATUS_CUSTOMERS, StatusCustomerSaga);
    yield takeLatest(constant.UPDATE_CUSTOMERS_STATUS , Updatacustomerstatus);
}