import * as constant from "../constants/LocationConstants";

const initialState = {
    location_list: [],
    location: null,
    loading: false,
    error: null,
    create_success: false,
    update_success: false,
    delete_success: null,
    location_message: null,
    update_status_success: null,
};

const LocationReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_LOCATION_INIT:
        case constant.CREATE_LOCATION_INIT:
        case constant.UPDATE_LOCATION_INIT:
        case constant.VIEW_LOCATION_INIT:
        case constant.DELETE_LOCATION_INIT:
        case constant.LOCATION_CHECK_INIT:
        case constant.LOCATION_STATUS_INIT:
            return {
                ...state,
                loading: true,
                create_success: false,
                update_success: false,
                error:null,
            };

        case constant.FETCH_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                location_list: response.data,
                error:null,
                location:null
            };

        // case constant.FETCH_LOCATION_COUNT_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //     };

        case constant.CREATE_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                create_success: response,
            };

        case constant.UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                update_success: response
            };

        case constant.VIEW_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                location: response.data,
                error:null
            };

        case constant.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };
            case constant.LOCATION_CHECK_SUCCESS:
                console.log(response.data);
                return {
                    ...state,
                    loading: false,
                    location_message: response.data,
                };
            case constant.LOCATION_STATUS_SUCCESS:
                    
                    return {
                        ...state,
                        loading: false,
                        update_status_success: response
                    };
        case constant.FETCH_LOCATION_ERROR:
        case constant.CREATE_LOCATION_ERROR:
        case constant.UPDATE_LOCATION_ERROR:
        case constant.VIEW_LOCATION_ERROR:
        case constant.DELETE_LOCATION_ERROR:
        case constant.LOCATION_CHECK_ERROR:
        case constant.LOCATION_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
                location:null
            };
        default:
            return state;
    }
};

export default LocationReducer;
