import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { USER_NAME, USER_ROLE, API_IMAGE_URL } from 'configs/AppConfig'
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { Link } from 'react-router-dom';
import { UserCircleGear } from 'phosphor-react';


const role = localStorage.getItem("auth_role")
const image_api_url = API_IMAGE_URL;

const menuItem = [{
  title: "Profile",
  icon: EditOutlined,
  path: "/" + role + "/my-profile"
}
]

export const NavProfile = (props) => {

  const { signOut, profile_update_success } = props;
  let logo = localStorage.getItem('companyProfileLogo');
  const [currentLogo, setLogo] = useState(logo);
  const [userName, setUserName] = useState(USER_NAME);

  useEffect(() => {
    if (profile_update_success) {
      setLogo(profile_update_success.data.logo);
      setUserName(profile_update_success.data.name);
    }
  }, [profile_update_success]);

  const profileMenu = (

    <div className="nav-profile nav-dropdown" >
      <div className="nav-profile-header">
        <div className="d-flex">
          {USER_ROLE === 'Driver' &&
            <Avatar size={60} src={image_api_url + "/" + currentLogo} />
          }
          <div className="pl-3">
            <h6 className="mb-0"><b>{userName}</b></h6>
            <span className="text-muted">{USER_ROLE === 'Driver' ? 'Company' : USER_ROLE}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <div>
            {USER_ROLE === 'Driver' &&
              <Menu.Item key={menuItem.length + 1}>
                <Link to={"/driver/configuration"}>
                  <span>
                    <SettingOutlined className="mr-3" />
                    <span className="font-weight-normal">Configuration</span>
                  </span>
                </Link>
              </Menu.Item>
            }
          </div>
          <Menu.Item key={menuItem.length + 2} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]} style={{ position: 'fixed' }}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          {USER_ROLE === 'Driver' ?
            <Avatar size={60} src={image_api_url + "/" + currentLogo} />
            : <UserCircleGear size={60} />}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state) => {
  return {
    profile_update_success: state.profile.profile_update_success,
    profile_view_success: state.profile.profile_view_success,
  }
}

export default connect(mapStateToProps, { signOut })(NavProfile)