import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/DashboardConstants";
import DashboardService from "../../services/DashboardService";

export function* viewlatestorderSaga(payload) {
    try {
        const response = yield call(DashboardService.Viewlatestorder, payload);
        yield put({ type: constant.VIEW_LATESTORDER_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_LATESTORDER_ERROR, error })
    }
}
export function* viewlatestemployeeSaga(payload) {
    try {
        const response = yield call(DashboardService.Viewlatestemployee, payload);
        yield put({ type: constant.VIEW_LATESTEMPLOYEE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_LATESTEMPLOYEE_ERROR, error })
    }
}

export default function* DashboardSaga() {
    yield takeLatest(constant.VIEW_LATESTORDER, viewlatestorderSaga);
    yield takeLatest(constant.VIEW_LATESTEMPLOYEE, viewlatestemployeeSaga);
}
