import fetch from 'auth/FetchInterceptor';

const ShipmentService = {}

ShipmentService.FetchShipmentInit = function (paginationParams) {
    return fetch({
        url: `/total-dispatch-list?status=${paginationParams.data.status}&per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}`,
        method: 'get'
    })
}

ShipmentService.ViewOrderShipment = function (data) {
    
    return fetch({
        url: '/dispatch-shipment/' + data.shipId,
        method: 'get'
    })
}



export default ShipmentService

