import * as constant from "../constants/LivetrackingConstants";

const initialState = {
    loading: false,
    error: null,
    trackinglist: [],
    trackingone: [],
    trackingAssignedRoute: [],
    liveRoute: []
};

const LivetrackingReducer = (state = initialState, { type, response }) => {

    switch (type) {
        case constant.FETCH_TRACKINGLIST_INIT:
        case constant.FETCH_TRACKINGONE_INIT:
        case constant.FETCH_ASSIGNEDROUTE_INIT:
            return {
                ...state,
                loading: true,
            };

        case constant.FETCH_LIVEROUTE_INIT:
            return {
                ...state,
            };

        case constant.FETCH_TRACKINGLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                trackinglist: response.data,
            };

        case constant.FETCH_TRACKINGONE_SUCCESS:
            return {
                ...state,
                loading: false,
                trackingone: response.data,
            };

        case constant.FETCH_ASSIGNEDROUTE_SUCCESS:

            return {
                ...state,
                loading: false,
                trackingAssignedRoute: response.routes,
            };

        case constant.FETCH_LIVEROUTE_SUCCESS:
            return {
                ...state,
                loading: false,
                liveRoute: response.data,
            };

        case constant.FETCH_TRACKINGLIST_ERROR:
        case constant.FETCH_TRACKINGONE_ERROR:
        case constant.FETCH_ASSIGNEDROUTE_ERROR:
        case constant.FETCH_LIVEROUTE_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};
export default LivetrackingReducer;