export const FETCH_ORDERS_INIT = 'FETCH_ORDERS_INIT';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';

export const CREATE_ORDER_INIT = 'CREATE_ORDER_INIT';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';

export const UPDATE_ORDER_INIT = 'UPDATE_ORDER_INIT';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';

export const VIEW_ORDER_INIT = 'VIEW_ORDER_INIT';
export const VIEW_ORDER_SUCCESS = 'VIEW_ORDER_SUCCESS';
export const VIEW_ORDER_ERROR = 'VIEW_ORDER_ERROR';

export const DELETE_ORDER_INIT = 'DELETE_ORDER_INIT';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR';

export const MOVE_TO_DISPATCH = 'MOVE_TO_DISPATCH';
export const MOVE_TO_DISPATCH_SUCCESS = 'MOVE_TO_DISPATCH_SUCCESS';
export const MOVE_TO_DISPATCH_ERROR = 'MOVE_TO_DISPATCH_ERROR';

export const FETCH_ORDERS_COUNT_INIT = 'FETCH_ORDERS_COUNT_INIT';
export const FETCH_ORDERS_COUNT_SUCCESS = 'FETCH_ORDERS_COUNT_SUCCESS';
export const FETCH_ORDERS_COUNT_ERROR = 'FETCH_ORDERS_COUNT_ERROR';

export const FETCH_CUS_FROM_ORDER_INIT = 'FETCH_CUS_FROM_ORDER_INIT';
export const FETCH_CUS_FROM_ORDER_SUCCESS = 'FETCH_CUS_FROM_ORDER_SUCCESS';
export const FETCH_CUS_FROM_ORDER_ERROR= 'FETCH_CUS_FROM_ORDER_ERROR';


export const FETCH_POST_ORDER_INIT = 'FETCH_POST_ORDER_INIT';
export const FETCH_POST_ORDER_SUCCESS = 'FETCH_POST_ORDER_SUCCESS';
export const FETCH_POST_ORDER_ERROR= 'FETCH_POST_ORDER_ERROR';


