export const FETCH_VENDORS = 'FETCH_VENDORS';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_ERROR = 'FETCH_VENDORS_ERROR';

export const DELETE_VENDORS = 'DELETE_VENDORS';
export const DELETE_VENDORS_SUCCESS = 'DELETE_VENDORS_SUCCESS';
export const DELETE_VENDORS_ERROR = 'DELETE_VENDORS_ERROR';

export const CREATE_VENDORS = 'CREATE_VENDORS';
export const CREATE_VENDORS_SUCCESS = 'CREATE_VENDORS_SUCCESS';
export const CREATE_VENDORS_ERROR = 'CREATE_VENDORS_ERROR';

export const VIEW_VENDORS = 'VIEW_VENDORS';
export const VIEW_VENDORS_SUCCESS = 'VIEW_VENDORS_SUCCESS';
export const VIEW_VENDORS_ERROR = 'VIEW_VENDORS_ERROR';

export const UPDATE_VENDORS = 'UPDATE_VENDORS';
export const UPDATE_VENDORS_SUCCESS = 'UPDATE_VENDORS_SUCCESS';
export const UPDATE_VENDORS_ERROR = 'UPDATE_VENDORS_ERROR';

export const UPDATE_VENDORS_STATUS = 'UPDATE_VENDORS_STATUS';
export const UPDATE_VENDORS_STATUS_SUCCESS = 'UPDATE_VENDORS_STATUS_SUCCESS';
export const UPDATE_VENDORS_STATUS_ERROR = 'UPDATE_VENDORS_STATUS_ERROR';

export const CREATE_CONTACTVENDORS = 'CREATE_CONTACTVENDORS';
export const CREATE_CONTACTVENDORS_SUCCESS = 'CREATE_CONTACTVENDORS_SUCCESS';
export const CREATE_CONTACTVENDORS_ERROR = 'CREATE_CONTACTVENDORS_ERROR';

export const UPDATE_CONTACTVENDORS = 'UPDATE_CONTACTVENDORS';
export const UPDATE_CONTACTVENDORS_SUCCESS = 'UPDATE_CONTACTVENDORS_SUCCESS';
export const UPDATE_CONTACTVENDORS_ERROR = 'UPDATE_CONTACTVENDORS_ERROR';

export const DELETE_CONTACTVENDORS = 'DELETE_CONTACTVENDORS';
export const DELETE_CONTACTVENDORS_SUCCESS = 'DELETE_CONTACTVENDORS_SUCCESS';
export const DELETE_CONTACTVENDORS_ERROR = 'DELETE_CONTACTVENDORS_ERROR';

export const CREATEBTN_VENDORS = 'CREATEBTN_VENDORS';
export const CREATEBTN_VENDORS_SUCCESS = 'CREATEBTN_VENDORS_SUCCESS';
export const CREATEBTN_VENDORS_ERROR = 'CREATEBTN_VENDORS_ERROR';

export const SEARCH_VENDORS = 'SEARCH_VENDORS';
export const SEARCH_VENDORS_SUCCESS = 'SEARCH_VENDORS_SUCCESS';
export const SEARCH_VENDORS_ERROR = 'SEARCH_VENDORS_ERROR';
export const INTERCEPTOR_ERROR = 'INTERCEPTOR_ERROR';


export const CREATE_DOCUMENTVENDOR = 'CREATE_DOCUMENTVENDOR';
export const CREATE_DOCUMENTVENDOR_SUCCESS = 'CREATE_DOCUMENTVENDOR_SUCCESS';
export const CREATE_DOCUMENTVENDOR_ERROR = 'CREATE_DOCUMENTVENDOR_ERROR';

export const UPDATE_DOCUMENTVENDOR = 'UPDATE_DOCUMENTVENDOR';
export const UPDATE_DOCUMENTVENDOR_SUCCESS = 'UPDATE_DOCUMENTVENDOR_SUCCESS';
export const UPDATE_DOCUMENTVENDOR_ERROR = 'UPDATE_DOCUMENTVENDOR_ERROR';

export const DELETE_DOCUMENTVENDOR = 'DELETE_DOCUMENTVENDOR';
export const DELETE_DOCUMENTVENDOR_SUCCESS = 'DELETE_DOCUMENTVENDOR_SUCCESS';
export const DELETE_DOCUMENTVENDOR_ERROR = 'DELETE_DOCUMENTVENDOR_ERROR';

export const VENDOR_DOCUMET_VIEW_INIT = 'VENDOR_DOCUMET_VIEW_INIT';
export const VENDOR_DOCUMET_VIEW_SUCCESS = 'VENDOR_DOCUMET_VIEW_SUCCESS';
export const VENDOR_DOCUMET_VIEW_ERROR = 'VENDOR_DOCUMET_VIEW_ERROR';

export const VENDOR_UPLOADED_DOCUMET_DELETE_INIT = 'VENDOR_UPLOADED_DOCUMET_DELETE_INIT';
export const VENDOR_UPLOADED_DOCUMET_DELETE_SUCCESS = 'VENDOR_UPLOADED_DOCUMET_DELETE_SUCCESS';
export const VENDOR_UPLOADED_DOCUMET_DELETE_ERROR = 'VENDOR_UPLOADED_DOCUMET_DELETE_ERROR';