import React, { useState } from 'react';
import { Menu, Dropdown, Badge, List, Button } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { Link, useHistory, withRouter } from 'react-router-dom';

const getIcon = icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}




export const NavNotification = (props) => {
  const { notificationLimitData } = props;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(notificationLimitData)
  const history = useHistory();
  const history2 = useHistory();

  const handleNotify = (item) => {
    setVisible(false);
    history2.push(`/driver/customer/view/${item.customer_id}`)
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }
  const handleclick = () => {
    setVisible(false);
    history.push("/driver/employee/notification");
  }
  const getNotificationBody = list => {
    return list !== 0 ?
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={item => (
          <List.Item className="list-clickable"
            onClick={() => handleNotify(item)}
          >
            <Flex alignItems="center">
              {/* <div className="pr-3">
            {item.img? <Avatar src={`/img/avatars/${item.img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}
          </div> */}

              <div className="mr-3" >
                <span className="font-weight-bold text-dark mr-5">{item.type}</span>
                <span className="text-gray-light mr-4">{item.cetificate_name}</span>
              </div>
              <small className="ml-auto mr-2">{item.expirt_date}</small>
            </Flex>
          </List.Item>
        )}
      />
      :
      <div className="empty-notification">
        <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
        <p className="mt-3">You have viewed all notifications</p>
      </div>;
  }
  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        {/* <Button className="text-primary" type="text" onClick={() => setData([])} size="small">Clear </Button> */}
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(notificationLimitData)}
      </div>
      {
        notificationLimitData !== 0 ?
          //data!== 0 ? 
          <div className="nav-notification-footer">
            {/* <a className="d-block" href="/driver/employee/notification">View all</a> */}
            <Link onClick={handleclick} className="d-block">View all</Link>
            {/* <Button onClick={handleclick} className="d-block">View all</Button> */}
          </div>
          :
          null
      }
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          {/* <Badge count={data.length}> */}
          <Badge count={data}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

export default withRouter(NavNotification)
