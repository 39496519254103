import * as constant from "../constants/ServiceConstants";

const initialState = {
    loading: false,
    error: null,
    service_list:[]
};

const ServiceReducer = (state = initialState, {type, response}) => {
    switch (type) {
        case constant.SERVICEFETCH_EQUIPMENTS:
        case constant.SERVICECREATE_EQUIPMENTS:
        case constant.VIEWSERVICE_EQUIPMENTS:
            return {
                ...state,
                loading: true,
            }

        case constant.SERVICEFETCH_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                service_list: response.data.data,
            }
   
        case constant.VIEWSERVICE_EQUIPMENTS_SUCCESS: 
            return {        
                ...state,
                loading: false,
                service_list:response.data,
            }

        case constant.SERVICECREATE_EQUIPMENTS_SUCCESS:
            return { 
                ...state, 
                loading:false,                          
                service_list:response
            }
      
        case constant.VIEWSERVICE_EQUIPMENTS_ERROR:
        case constant.SERVICEFETCH_EQUIPMENTS_ERROR:
        case constant.SERVICECREATE_EQUIPMENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            }

        default:
            return state;
    }
};
export default ServiceReducer;