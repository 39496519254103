import fetch from 'auth/FetchInterceptor';

const ExpenseService = {}

ExpenseService.FetchVendorPays = function (payload) {

    let url = "/billing-vendor-list?vendor_id=" + payload.data.vendorId +
        "&from_date=" + payload.data.fromDate +
        "&to_date=" + payload.data.toDate +
        "&page=" + payload.data.page +
        "&per_page=" + payload.data.per_page +
        "&search=" + payload.data.search;
    return fetch({
        url: url,
        method: 'get'
    })
}

ExpenseService.FetchEmployeePays = function (payload) {
    let url = "/billing-employee-list?employee_id=" + payload.data.employeeId +
        "&from_date=" + payload.data.fromDate +
        "&to_date=" + payload.data.toDate +
        "&page=" + payload.data.page +
        "&per_page=" + payload.data.per_page +
        "&search=" + payload.data.search;
    return fetch({
        url: url,
        method: 'get'
    })
}
ExpenseService.FetchOwnerOperatorPays = function (payload) {
    let url = "/billing-operator-list?operator_id=" + payload.data.operatorId +
        "&from_date=" + payload.data.fromDate +
        "&to_date=" + payload.data.toDate +
        "&page=" + payload.data.page +
        "&per_page=" + payload.data.per_page +
        "&search=" + payload.data.search;
    return fetch({
        url: url,
        method: 'get'
    })
}
ExpenseService.PayVendorApi = function (formData) {
    return fetch({
        url: '/billing-vendor-pay',
        method: 'post',
        data: formData
    })
}
ExpenseService.PayEmployeeApi = function (formData) {
    return fetch({
        url: '/billing-employee-pay',
        method: 'post',
        data: formData
    })
}
ExpenseService.PayOwnerOperatorApi = function (formData) {
    return fetch({
        url: '/billing-owner-operator-pay',
        method: 'post',
        data: formData
    })
}
ExpenseService.PaidListApi = function (payload) {
    let url = "/payable-list?page=" + payload.data.page + "&per_page=" + payload.data.per_page + "&search=" + payload.data.search + "&date=" + payload.data.date + "&type=" + payload.data.status;

    return fetch({
        url: url,
        method: 'get'
    })
}

ExpenseService.SinglePayList = function (formData) {
    return fetch({
        url: '/billing-view/' + formData.data,
        method: 'get',
    })
}

ExpenseService.ExpensePdfMailSend = function (data) {
    return fetch({
        url: '/payable-email/' + data.id.id,
        method: 'post',
        data: data.id.values
    })
}

export default ExpenseService

