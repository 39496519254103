export const FETCH_EQUIPMENTS = 'FETCH_EQUIPMENTS';
export const FETCH_EQUIPMENTS_SUCCESS = 'FETCH_EQUIPMENTS_SUCCESS';
export const FETCH_EQUIPMENTS_ERROR = 'FETCH_EQUIPMENTS_ERROR';

export const VIEW_EQUIPMENTS = 'VIEW_EQUIPMENTS';
export const VIEW_EQUIPMENTS_SUCCESS = 'VIEW_EQUIPMENTS_SUCCESS';
export const VIEW_EQUIPMENTS_ERROR = 'VIEW_EQUIPMENTS_ERROR';

export const CREATE_EQUIPMENTS = 'CREATE_EQUIPMENTS';
export const CREATE_EQUIPMENTS_SUCCESS = 'CREATE_EQUIPMENTS_SUCCESS';
export const CREATE_EQUIPMENTS_ERROR = 'CREATE_EQUIPMENTS_ERROR';

export const DELETE_EQUIPMENTS = 'DELETE_EQUIPMENTS';
export const DELETE_EQUIPMENTS_SUCCESS = 'DELETE_EQUIPMENTS_SUCCESS';
export const DELETE_EQUIPMENTS_ERROR = 'DELETE_EQUIPMENTS_ERROR';

export const UPDATE_EQUIPMENTS = 'UPDATE_EQUIPMENTS';
export const UPDATE_EQUIPMENTS_SUCCESS = 'UPDATE_EQUIPMENTS_SUCCESS';
export const UPDATE_EQUIPMENTS_ERROR = 'UPDATE_EQUIPMENTS_ERROR';

export const UPDATE_EQUIPMENTS_STATUS = 'UPDATE_EQUIPMENTS_STATUS';
export const UPDATE_EQUIPMENTS_STATUS_SUCCESS = 'UPDATE_EQUIPMENTS_STATUS_SUCCESS';
export const UPDATE_EQUIPMENTS_STATUS_ERROR = 'UPDATE_EQUIPMENTS_STATUS_ERROR';

export const CREATE_SERVICEEQUIPMENTS = 'CREATE_SERVICEEQUIPMENTS';
export const CREATE_SERVICEEQUIPMENTS_SUCCESS = 'CREATE_SERVICEEQUIPMENTS_SUCCESS';
export const CREATE_SERVICEEQUIPMENTS_ERROR = 'CREATE_SERVICEEQUIPMENTS_ERROR';

export const UPDATE_SERVICEEQUIPMENTS = 'UPDATE_SERVICEEQUIPMENTS';
export const UPDATE_SERVICEEQUIPMENTS_SUCCESS = 'UPDATE_SERVICEEQUIPMENTS_SUCCESS';
export const UPDATE_SERVICEEQUIPMENTS_ERROR = 'UPDATE_SERVICEEQUIPMENTS_ERROR';

export const DELETE_SERVICEEQUIPMENTS = 'DELETE_SERVICEEQUIPMENTS';
export const DELETE_SERVICEEQUIPMENTS_SUCCESS = 'DELETE_SERVICEEQUIPMENTS_SUCCESS';
export const DELETE_SERVICEEQUIPMENTS_ERROR = 'DELETE_SERVICEEQUIPMENTS_ERROR';

export const FETCH_EQUIPMENTS_STATUS = 'FETCH_EQUIPMENTS_STATUS';
export const FETCH_EQUIPMENTS_STATUS_SUCCESS = 'FETCH_EQUIPMENTS_STATUS_SUCCESS';
export const FETCH_EQUIPMENTS_STATUS_ERROR = 'FETCH_EQUIPMENTS_STATUS_ERROR';

export const CREATEBTN_EQUIPMENTS = 'CREATEBTN_EQUIPMENTS';
export const CREATEBTN_EQUIPMENTS_SUCCESS = 'CREATEBTN_EQUIPMENTS_SUCCESS';
export const CREATEBTN_EQUIPMENTS_ERROR = 'CREATEBTN_EQUIPMENTS_ERROR';

export const SEARCH_EQUIPMENTS = 'SEARCH_EQUIPMENTS';
export const SEARCH_EQUIPMENTS_SUCCESS = 'SEARCH_EQUIPMENTS_SUCCESS';
export const SEARCH_EQUIPMENTS_ERROR = 'SEARCH_EQUIPMENTS_ERROR';

export const EQUIPMENTSTATUS_SINGLEVIEW = 'EQUIPMENTSTATUS_SINGLEVIEW';
export const EQUIPMENTSTATUS_SINGLEVIEW_SUCCESS = 'EQUIPMENTSTATUS_SINGLEVIEW_SUCCESS';
export const EQUIPMENTSTATUS_SINGLEVIEW_ERROR = 'EQUIPMENTSTATUS_SINGLEVIEW_ERROR';

export const FETCH_EQUIPMENT_EXPIRY = 'FETCH_EQUIPMENT_EXPIRY';
export const FETCH_EQUIPMENT_EXPIRY_SUCCESS = 'FETCH_EQUIPMENT_EXPIRY_SUCCESS';
export const FETCH_EQUIPMENT_EXPIRY_ERROR = 'FETCH_EQUIPMENT_EXPIRY_ERROR';

