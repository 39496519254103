import fetch from 'auth/FetchInterceptor'
import { MAPBOX_TOKEN } from '../configs/AppConfig';
import axios from 'axios';
const LivetrackingService = {}

LivetrackingService.FetchDispatchTrackingApi = function () {
    return fetch({
        url: '/dispatch-tracking',
        method: 'get',
    })
}
LivetrackingService.FetchTrackingOneApi = function (request) {

    return fetch({
        url: '/dispatch-track-shipment/' + request.dispatch_id,
        method: 'get',
    })
}

LivetrackingService.FetchAssignedRoutesApi = function (request) {

    return fetch({
        url: `https://api.mapbox.com/directions/v5/mapbox/driving/${request.pickup_lon},${request.pickup_lat};${request.delivery_lon},${request.delivery_lat}?steps=true&geometries=geojson&access_token=` + MAPBOX_TOKEN,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

LivetrackingService.FetchLiveRouteApi = function (request) {
    return fetch({
        url: '/dispatch/' + request.dispatch_id + '/tracking/after/' + request.last_id,
        method: 'get',
    })
}

export default LivetrackingService

