import * as constant from "../constants/CustomerConstants";

const initialState = {
    customer_list: [],
    country_list: [],
    loading: false,
    error: null,
    data: [],
    viewcustomer_list: null,
    contact_data: null,
    document_data: [],
    create_success: false,
    update_success: false,
    delete_success: false,
    single_customer: null,
    contacteditvalues: [],
    document_created: null,
    document_update: null,
    customer_expirylist: null,
    doc_uploaded_delete_success: null,
    single_expirycustomer: null,
    update_status :null

};

const CustomerReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_CUSTOMERS:
        case constant.FETCH_COUNTRY:
        case constant.CREATE_CUSTOMERS:
        case constant.VIEW_CUSTOMERS:
        case constant.DELETE_CUSTOMERS:
        case constant.UPDATE_CUSTOMERS:
        case constant.UPDATE_CUSTOMERS_STATUS:
        case constant.CREATE_CUSTOMERS_CONTACT:
        case constant.UPDATE_CUSTOMERS_CONTACT:
        case constant.CREATE_CUSTOMERS_DOCUMENT:
        case constant.DELETE_CUSTOMERS_CONTACT:
        case constant.UPDATE_CUSTOMERS_DOCUMENT:
        case constant.DELETE_CUSTOMERS_DOCUMENT:
        case constant.CUSTOMER_UPLOADED_DOC_DELETE_INIT:
        case constant.CREATEUPDATE_EXPIRY_CUSTOMERS:
        case constant.FETCH_EXPIRY_CUSTOMERS:
        case constant.VIEW_EXPIRY_CUSTOMERS:
        case constant.STATUS_CUSTOMERS:
            return {
                ...state,
                loading: true,
                create_success: false,
                update_success: false,
                delete_success: false,
               
            };
        case constant.FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customer_list: response.data,
            };
        case constant.FETCH_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                country_list: response.data,
            };

        case constant.CREATE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                create_success: response,

            };

        case constant.UPDATE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                update_success: response,
            };

        case constant.UPDATE_CUSTOMERS_STATUS_SUCCESS:
            return {
                ...state,
                loading :false,
                update_status : response
            }

        case constant.DELETE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };

        case constant.VIEW_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_customer: response.data,
            };

        case constant.CREATE_CUSTOMERS_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact_data: response,
                contacteditvalues: [],
                create_success: response
            };

        case constant.UPDATE_CUSTOMERS_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact_data: response,
                update_success: response
            };

        case constant.CREATE_CUSTOMERS_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                document_data: response,
                document_created: response,
            };
        case constant.UPDATE_CUSTOMERS_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                document_data: response,
                document_update: response
            };
        case constant.DELETE_CUSTOMERS_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };
        case constant.CUSTOMER_UPLOADED_DOC_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                doc_uploaded_delete_success: response
            };
        case constant.DELETE_CUSTOMERS_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };
        case constant.CREATEBTN_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_customer: null,
            };
        case constant.SEARCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customer_list: response.data,
            };
        case constant.CREATEUPDATE_EXPIRY_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response,
            };
        case constant.FETCH_EXPIRY_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customer_expirylist: response.data,
            };

        case constant.VIEW_EXPIRY_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_expirycustomer: response.data,
            };
        case constant.STATUS_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response,
            };
        case constant.FETCH_CUSTOMERS_ERROR:
        case constant.FETCH_COUNTRY_ERROR:
        case constant.CREATE_CUSTOMERS_ERROR:
        case constant.VIEW_CUSTOMERS_ERROR:
        case constant.DELETE_CUSTOMERS_ERROR:
        case constant.UPDATE_CUSTOMERS_ERROR:
        case constant.CREATE_CUSTOMERS_CONTACT_ERROR:
        case constant.UPDATE_CUSTOMERS_CONTACT_ERROR:
        case constant.DELETE_CUSTOMERS_CONTACT_ERROR:
        case constant.CREATE_CUSTOMERS_DOCUMENT_ERROR:
        case constant.UPDATE_CUSTOMERS_DOCUMENT_ERROR:
        case constant.DELETE_CUSTOMERS_DOCUMENT_ERROR:
        case constant.CUSTOMER_UPLOADED_DOC_DELETE_ERROR:
        case constant.CREATEUPDATE_EXPIRY_CUSTOMERS_ERROR:
        case constant.FETCH_EXPIRY_CUSTOMERS_ERROR:
        case constant.VIEW_EXPIRY_CUSTOMERS_ERROR:
        case constant.STATUS_CUSTOMERS_ERROR:
        case constant.UPDATE_CUSTOMERS_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};
export default CustomerReducer;