import * as constant from "../constants/EquipmentConstants";

const initialState = {
    equipment_list: [],
    success: false,
    single_equipment: null,
    loading: false,
    error: null,
    data: null,
    create_success: null,
    update_success: null,
    service_list: [],
    equipmentStatus: [],
    new_service: [],
    finishformsubmit: [],
    newowned_equip: [],
    delete_success: null,
    equipmentStatusSingleview: [],
    document_created: null,
    document_updated: null,
    document_delete: null,
    create_equipmentsuccess: null,
    delete_equipmentsuccess: null,
    update_equipmentsuccess: null,
    update_status_data: null,
    equipment_expiry_data: null,
};

const EquipmentReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_EQUIPMENTS:
        case constant.VIEW_EQUIPMENTS:
        case constant.CREATE_EQUIPMENTS:
        case constant.DELETE_EQUIPMENTS:
        case constant.UPDATE_EQUIPMENTS:
        case constant.UPDATE_EQUIPMENTS_STATUS:
        case constant.CREATE_SERVICEEQUIPMENTS:
        case constant.UPDATE_SERVICEEQUIPMENTS:
        case constant.DELETE_SERVICEEQUIPMENTS:
        case constant.FETCH_EQUIPMENTS_STATUS:
        case constant.EQUIPMENTSTATUS_SINGLEVIEW:
        case constant.FETCH_EQUIPMENT_EXPIRY:
            return {
                ...state,
                loading: true,
                create_success: false,
                update_success: false
            };

        case constant.FETCH_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                equipment_list: response.data,
            };
        case constant.VIEW_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_equipment: response.data,
            };
        case constant.DELETE_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
                delete_equipmentsuccess: response,
            };
        case constant.CREATE_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                create_success: response?.status,
                create_equipmentsuccess: response,
                newowned_equip: response?.data,
            };
        case constant.UPDATE_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                update_success: response.status,
                newowned_equip: response.data,
                update_equipmentsuccess: response
            };
        case constant.UPDATE_EQUIPMENTS_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                update_status_data: response
            };
        case constant.CREATE_SERVICEEQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                new_service: response.data,
                finishformsubmit: [],
                document_created: response,
            };
        case constant.UPDATE_SERVICEEQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                new_service: response.data,
                document_updated: response,
            };

        case constant.DELETE_SERVICEEQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                document_delete: response,
            };

        case constant.FETCH_EQUIPMENTS_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                equipmentStatus: response.data
            };

        case constant.CREATEBTN_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_equipment: null,
            };
        case constant.SEARCH_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                equipment_list: response.data,
            };
        case constant.EQUIPMENTSTATUS_SINGLEVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                equipmentStatusSingleview: response.data,
            };
        case constant.FETCH_EQUIPMENT_EXPIRY_SUCCESS:
            return {
                ...state,
                loading: false,
                equipment_expiry_data: response.data,
            }
        case constant.FETCH_EQUIPMENTS_ERROR:
        case constant.VIEW_EQUIPMENTS_ERROR:
        case constant.CREATE_EQUIPMENTS_ERROR:
        case constant.UPDATE_EQUIPMENTS_ERROR:
        case constant.CREATE_SERVICEEQUIPMENTS_ERROR:
        case constant.UPDATE_SERVICEEQUIPMENTS_ERROR:
        case constant.DELETE_SERVICEEQUIPMENTS_ERROR:
        case constant.FETCH_EQUIPMENTS_STATUS_ERROR:
        case constant.EQUIPMENTSTATUS_SINGLEVIEW_ERROR:
        case constant.UPDATE_EQUIPMENTS_STATUS_ERROR:
        case constant.FETCH_EQUIPMENT_EXPIRY_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};
export default EquipmentReducer;