import * as constant from "../constants/QuoteConstants";

const initialState = {
    quotes_list: [],
    quotes: null,
    loading: false,
    error: null,
    create_success: false,
    update_success: false,
    delete_success: false,
    move_success: null,
    move_again_success: false,
    move_data: [],
    status_counts: null,
    pod_upload_success: null,
    delete_pod_success: null,
    quotemailsend_success: null,
};

const QuoteReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_QUOTES_INIT:
        case constant.CREATE_QUOTE_INIT:
        case constant.UPDATE_QUOTE_INIT:
        case constant.VIEW_QUOTE_INIT:
        case constant.DELETE_QUOTE_INIT:
        case constant.MOVE_TO_ORDER:
        case constant.MOVE_AGAIN_TO_ORDER:
        case constant.UPLOAD_POD_INIT:
        case constant.DELETE_POD_INIT:
        case constant.QUOTE_MAILSEND_INIT:
            return {
                ...state,
                loading: true,
            };

        case constant.FETCH_QUOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                quotes_list: response.data,
            };

        case constant.CREATE_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                create_success: response,
            };

        case constant.UPLOAD_POD_SUCCESS:
            return {
                ...state,
                loading: false,
                pod_upload_success: response,
            };

        case constant.FETCH_QUOTES_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                status_counts: response.data,
            };

        case constant.UPDATE_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                update_success: response
            };

        case constant.VIEW_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                quotes: response.data
            };

        case constant.DELETE_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };

        case constant.DELETE_POD_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_pod_success: response
            };

        case constant.MOVE_TO_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                move_success: response,
                move_data: response.data
            };

        case constant.MOVE_AGAIN_TO_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                move_again_success: response.data
            };

        case constant.QUOTE_MAILSEND_SUCCESS:
            return {
                ...state,
                loading: false,
                quotemailsend_success: response
            };

        case constant.FETCH_QUOTES_ERROR:
        case constant.CREATE_QUOTE_ERROR:
        case constant.UPDATE_QUOTE_ERROR:
        case constant.VIEW_QUOTE_ERROR:
        case constant.DELETE_QUOTE_ERROR:
        case constant.MOVE_TO_ORDER_ERROR:
        case constant.MOVE_AGAIN_TO_ORDER_ERROR:
        case constant.UPLOAD_POD_ERROR:
        case constant.DELETE_POD_ERROR:
        case constant.QUOTE_MAILSEND_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};

export default QuoteReducer;
