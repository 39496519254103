// Production

// const dev = {
//   API_ENDPOINT_URL: " https://admin.tevolatruckmanagement.com/api",
//   API_ENDPOINT_IMAGEVIEW: " https://admin.tevolatruckmanagement.com/storage",
//   API_ENDPOINT_DOCUMENTVIEW: "https://admin.tevolatruckmanagement.com/storage/",
//   API_ENDPOINT_EMPDOCUMENTVIEW: " https://admin.tevolatruckmanagement.com",
//   API_ENDPOINT_DOCUMENTDOWNLOAD: " https://admin.tevolatruckmanagement.com",
//   API_ENDPOINT_IMAGE_DOWNLOAD:
//     "https://admin.tevolatruckmanagement.com/api/download?file_name=",
// };
// const prod = {
//   API_ENDPOINT_URL: " https://admin.tevolatruckmanagement.com/api",
//   API_ENDPOINT_IMAGEVIEW: " https://admin.tevolatruckmanagement.com/storage",
//   API_ENDPOINT_DOCUMENTVIEW: "https://admin.tevolatruckmanagement.com/storage/",
//   API_ENDPOINT_EMPDOCUMENTVIEW: " https://admin.tevolatruckmanagement.com",
//   API_ENDPOINT_DOCUMENTDOWNLOAD: " https://admin.tevolatruckmanagement.com",
//   API_ENDPOINT_IMAGE_DOWNLOAD:
//     "https://admin.tevolatruckmanagement.com/api/download?file_name=",
// };

// const test = {
//   API_ENDPOINT_URL: " https://admin.tevolatruckmanagement.com/api",
//   API_ENDPOINT_IMAGEVIEW: " https://admin.tevolatruckmanagement.com/storage/",
//   API_ENDPOINT_DOCUMENTVIEW: " https://admin.tevolatruckmanagement.com/storage",
//   API_ENDPOINT_EMPDOCUMENTVIEW: " https://admin.tevolatruckmanagement.com",
//   API_ENDPOINT_DOCUMENTDOWNLOAD: " https://admin.tevolatruckmanagement.com",
//   API_ENDPOINT_IMAGE_DOWNLOAD:
//     "https://admin.tevolatruckmanagement.com/api/download?file_name=",
// };

// staging

const dev = {
  API_ENDPOINT_URL: "https://tms-admin.stagingurl.tk/api",
  API_ENDPOINT_IMAGEVIEW: "https://tms-admin.stagingurl.tk/storage",
  API_ENDPOINT_DOCUMENTVIEW: " https://tms-admin.stagingurl.tk/storage/",
  API_ENDPOINT_EMPDOCUMENTVIEW: " https://tms-admin.stagingurl.tk",
  API_ENDPOINT_DOCUMENTDOWNLOAD: " https://tms-admin.stagingurl.tk",
  API_ENDPOINT_IMAGE_DOWNLOAD:
    "https://tms-admin.stagingurl.tk/api/download?file_name=",
};
// https://admin.tevolatruckmanagement.com/api/
const prod = {
  API_ENDPOINT_URL: "https://tms-admin.stagingurl.tk/api",
  API_ENDPOINT_IMAGEVIEW: "https://tms-admin.stagingurl.tk/storage",
  API_ENDPOINT_DOCUMENTVIEW: " https://tms-admin.stagingurl.tk/storage/",
  API_ENDPOINT_EMPDOCUMENTVIEW: " https://tms-admin.stagingurl.tk",
  API_ENDPOINT_DOCUMENTDOWNLOAD: " https://tms-admin.stagingurl.tk",
  API_ENDPOINT_IMAGE_DOWNLOAD:
    "https://tms-admin.stagingurl.tk/api/download?file_name=",
};

const test = {
  API_ENDPOINT_URL: "https://tms-admin.stagingurl.tk/api",
  API_ENDPOINT_IMAGEVIEW: "https://tms-admin.stagingurl.tk/storage",
  API_ENDPOINT_DOCUMENTVIEW: " https://tms-admin.stagingurl.tk/storage/",
  API_ENDPOINT_EMPDOCUMENTVIEW: " https://tms-admin.stagingurl.tk",
  API_ENDPOINT_DOCUMENTDOWNLOAD: " https://tms-admin.stagingurl.tk",
  API_ENDPOINT_IMAGE_DOWNLOAD:
    "https://tms-admin.stagingurl.tk/api/download?file_name=",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
