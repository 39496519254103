import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/CompanyConstants";
import CompanyService from "../../services/CompanyService";

export function* FetchCompanySaga(payload) {
    try {
        const response = yield call(CompanyService.FetchCompany, payload.data);
        yield put({ type: constant.FETCH_COMPANY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_COMPANY_ERROR, error })
    }
}
export function* CreateCompanySaga(payload) {
    try {
        const response = yield call(CompanyService.CreateCompany, payload.formData);
        yield put({ type: constant.CREATE_COMPANY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_COMPANY_ERROR, error })
    }
}
export function* CompanyStatusChangeSage(payload) {
    try {
        const response = yield call(CompanyService.CompanyStatusChange, payload);
        yield put({ type: constant.COMPANY_STATUS_CHANGE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.COMPANY_STATUS_CHANGE_ERROR, error })
    }
}
export function* updateCompanySaga(payload) {
    try {
        const response = yield call(CompanyService.UpdateCompanys, payload.data);
        yield put({ type: constant.EDIT_COMPANY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.EDIT_COMPANY_ERROR, error })
    }
}
export function* viewCompanySaga(payload) {
    try {
        const response = yield call(CompanyService.ViewCompany, payload);
        yield put({ type: constant.VIEW_COMPANY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_COMPANY_ERROR, error })
    }
}
export function* deleteCompanySaga(payload) {
    try {
        const response = yield call(CompanyService.DeleteCompany, payload);
        yield put({ type: constant.DELETE_COMPANY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_COMPANY_ERROR, error })
    }
}
export default function* CompanySaga() {
    yield takeLatest(constant.FETCH_COMPANY, FetchCompanySaga);
    yield takeLatest(constant.CREATE_COMPANY, CreateCompanySaga);
    yield takeLatest(constant.COMPANY_STATUS_CHANGE, CompanyStatusChangeSage);
    yield takeLatest(constant.EDIT_COMPANY, updateCompanySaga);
    yield takeLatest(constant.VIEW_COMPANY, viewCompanySaga);
    yield takeLatest(constant.DELETE_COMPANY, deleteCompanySaga);
}