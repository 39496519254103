export const FETCH_QUOTES_INIT = 'FETCH_QUOTES_INIT';
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_QUOTES_ERROR = 'FETCH_QUOTES_ERROR';

export const CREATE_QUOTE_INIT = 'CREATE_QUOTE_INIT';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const CREATE_QUOTE_ERROR = 'CREATE_QUOTE_ERROR';

export const UPDATE_QUOTE_INIT = 'UPDATE_QUOTE_INIT';
export const UPDATE_QUOTE_SUCCESS = 'UPDATE_QUOTE_SUCCESS';
export const UPDATE_QUOTE_ERROR = 'UPDATE_QUOTE_ERROR';

export const VIEW_QUOTE_INIT = 'VIEW_QUOTE_INIT';
export const VIEW_QUOTE_SUCCESS = 'VIEW_QUOTE_SUCCESS';
export const VIEW_QUOTE_ERROR = 'VIEW_QUOTE_ERROR';

export const DELETE_QUOTE_INIT = 'DELETE_QUOTE_INIT';
export const DELETE_QUOTE_SUCCESS = 'DELETE_QUOTE_SUCCESS';
export const DELETE_QUOTE_ERROR = 'DELETE_QUOTE_ERROR';

export const DELETE_POD_INIT = 'DELETE_POD_INIT';
export const DELETE_POD_SUCCESS = 'DELETE_POD_SUCCESS';
export const DELETE_POD_ERROR = 'DELETE_POD_ERROR';

export const MOVE_TO_ORDER = 'MOVE_TO_ORDER';
export const MOVE_TO_ORDER_SUCCESS = 'MOVE_TO_ORDER_SUCCESS';
export const MOVE_TO_ORDER_ERROR = 'MOVE_TO_ORDER_ERROR';

export const MOVE_AGAIN_TO_ORDER = 'MOVE_AGAIN_TO_ORDER';
export const MOVE_AGAIN_TO_ORDER_SUCCESS = 'MOVE_AGAIN_TO_ORDER_SUCCESS';
export const MOVE_AGAIN_TO_ORDER_ERROR = 'MOVE_AGAIN_TO_ORDER_ERROR';

export const FETCH_QUOTES_COUNT_INIT = 'FETCH_QUOTES_COUNT_INIT';
export const FETCH_QUOTES_COUNT_SUCCESS = 'FETCH_QUOTES_COUNT_SUCCESS';
export const FETCH_QUOTES_COUNT_ERROR = 'FETCH_QUOTES_COUNT_ERROR';

export const UPLOAD_POD_INIT = 'UPLOAD_POD_INIT';
export const UPLOAD_POD_SUCCESS = 'UPLOAD_POD_SUCCESS';
export const UPLOAD_POD_ERROR = 'UPLOAD_POD_ERROR';

export const QUOTE_MAILSEND_INIT = 'QUOTE_MAILSEND_INIT';
export const QUOTE_MAILSEND_SUCCESS = 'QUOTE_MAILSEND_SUCCESS';
export const QUOTE_MAILSEND_ERROR = 'QUOTE_MAILSEND_ERROR';