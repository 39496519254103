import { fork } from 'redux-saga/effects';
import Auth from './Auth';
import CustomerSaga from './CustomerSaga';
import EmployeeSaga from './EmployeeSaga';
import EquipmentSaga from './EquipmentSaga';
import ServiceSaga from './ServiceSaga';
import VendorSaga from './VendorSaga';
import EssentialSaga from './EssentialSaga';
import CompanySaga from './CompanySaga';
import QuoteSaga from './QuoteSaga';
import OrderSaga from './OrderSaga';
import DispatchSaga from './DispatchSaga';
import RevenueSaga from './RevenueSaga';
import ExpenseSaga from './ExpenseSaga';
import DashboardSaga from './DashboardSaga';
import ProfileSaga from './ProfileSaga';
import ShipmentSaga from './ShipmentSaga';
import LivetrackingSaga from './LivetrackingSaga';
import LocationSaga from './LocationSaga';

export default function* rootSaga(getState) {
    yield fork(Auth);
    yield fork(CustomerSaga);
    yield fork(EmployeeSaga);
    yield fork(EquipmentSaga);
    yield fork(ServiceSaga);
    yield fork(VendorSaga);
    yield fork(EssentialSaga);
    yield fork(CompanySaga);
    yield fork(QuoteSaga);
    yield fork(OrderSaga);
    yield fork(LocationSaga);
    yield fork(DispatchSaga);
    yield fork(RevenueSaga);
    yield fork(ExpenseSaga);
    yield fork(DashboardSaga);
    yield fork(ProfileSaga);
    yield fork(ShipmentSaga);
    yield fork(LivetrackingSaga);
}