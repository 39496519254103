import { takeLatest, put, call, } from "redux-saga/effects";
import * as constant from "../constants/ServiceConstants";
import EquipmentService from "../../services/ServiceService";

export function* servicefetchequipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.ServiceFetchEquipments, payload);
        yield put({ type: constant.SERVICEFETCH_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.SERVICEFETCH_EQUIPMENTS_ERROR, error });
    }
}
export function* servicecreateequipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.ServiceCreateEquipments, payload.data);
        yield put({ type: constant.SERVICECREATE_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.SERVICECREATE_EQUIPMENTS_ERROR, error });
    }
}
export function* viewserviceequipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.ViewServiceEquipments, payload.id);
        yield put({ type: constant.VIEWSERVICE_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.VIEWSERVICE_EQUIPMENTS_ERROR, error });
    }
}

export default function* ServiceSaga() {
    yield takeLatest(constant.VIEWSERVICE_EQUIPMENTS, viewserviceequipmentSaga);
    yield takeLatest(constant.SERVICEFETCH_EQUIPMENTS, servicefetchequipmentSaga);
    yield takeLatest(constant.SERVICECREATE_EQUIPMENTS, servicecreateequipmentSaga);
}