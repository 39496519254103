import * as constant from "../constants/DashboardConstants";
const initialState = {
    latest_order: [],
    latest_employee:[],
    data:[],
    loading: false,
    error: null,
};

const DashboardReducer = (state = initialState, { type, response }) => {
    switch (type) {

        case constant.VIEW_LATESTORDER:
        case constant.VIEW_LATESTEMPLOYEE:
            return {
                ...state,
                loading: true,
                data: response
            };

        case constant.VIEW_LATESTORDER_SUCCESS: 
            return {
                ...state,
                loading: true,
                latest_order:response.data,
            };

            case constant.VIEW_LATESTEMPLOYEE_SUCCESS: 
            return {
                ...state,
                loading: true,
                latest_employee:response.data,
            };

            case constant.VIEW_LATESTORDER_ERROR:
            case constant.VIEW_LATESTEMPLOYEE_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
            default:
                return state;
    }
}
export default DashboardReducer
