export const FETCH_DISPATCH_INIT = 'FETCH_DISPATCH_INIT';
export const FETCH_DISPATCH_SUCCESS = 'FETCH_DISPATCH_SUCCESS';
export const FETCH_DISPATCH_ERROR = 'FETCH_DISPATCH_ERROR';

export const VIEW_DISPATCH_INIT = 'VIEW_DISPATCH_INIT';
export const VIEW_DISPATCH_SUCCESS = 'VIEW_DISPATCH_SUCCESS';
export const VIEW_DISPATCH_ERROR = 'VIEW_DISPATCH_ERROR';

export const DELETE_DISPATCH_INIT = 'DELETE_DISPATCH_INIT';
export const DELETE_DISPATCH_SUCCESS = 'DELETE_DISPATCH_SUCCESS';
export const DELETE_DISPATCH_ERROR = 'DELETE_DISPATCH_ERROR';

export const DISPATCH_STATUS_INIT = 'DISPATCH_STATUS_INIT';
export const DISPATCH_STATUS_SUCCESS = 'DISPATCH_STATUS_SUCCESS';
export const DISPATCH_STATUS_ERROR = 'DISPATCH_STATUS_ERROR';

export const DISPATCH_ORDER_STATUS_INIT = 'DISPATCH_ORDER_STATUS_INIT';
export const DISPATCH_ORDER_STATUS_SUCCESS = 'DISPATCH_ORDER_STATUS_SUCCESS';
export const DISPATCH_ORDER_STATUS_ERROR = 'DISPATCH_ORDER_STATUS_ERROR';

export const DISPATCH_ORDER_SHIPMENT_STATUS_INIT = 'DISPATCH_ORDER_SHIPMENT_STATUS_INIT';
export const DISPATCH_ORDER_SHIPMENT_STATUS_SUCCESS = 'DISPATCH_ORDER_SHIPMENT_STATUS_SUCCESS';
export const DISPATCH_ORDER_SHIPMENT_STATUS_ERROR = 'DISPATCH_ORDER_SHIPMENT_STATUS_ERROR';

export const DISPATCH_CONTACT_INIT = 'DISPATCH_CONTACT_INIT';
export const DISPATCH_CONTACT_SUCCESS = 'DISPATCH_CONTACT_SUCCESS';
export const DISPATCH_CONTACT_ERROR = ' DISPATCH_CONTACT_ERROR';

export const ACCOUNT_REP_CREATE_INIT = 'ACCOUNT_REP_CREATE_INIT';
export const ACCOUNT_REP_CREATE_SUCCESS = 'ACCOUNT_REP_CREATE_SUCCESS';
export const ACCOUNT_REP_CREATE_ERROR = 'ACCOUNT_REP_CREATE_ERROR';

export const SALES_REP_CREATE_INIT = 'SALES_REP_CREATE_INIT';
export const SALES_REP_CREATE_SUCCESS = 'SALES_REP_CREATE_SUCCESS';
export const SALES_REP_CREATE_ERROR = 'SALES_REP_CREATE_ERROR';

export const FETCH_DISPATCH_COUNTS_INIT = 'FETCH_DISPATCH_COUNTS_INIT';
export const FETCH_DISPATCH_COUNTS_SUCCESS = 'FETCH_DISPATCH_COUNTS_SUCCESS';
export const FETCH_DISPATCH_COUNTS_ERROR = 'FETCH_DISPATCH_COUNTS_ERROR';

export const DISPATCH_COMPLETION_UPDATE_INIT = 'DISPATCH_COMPLETION_UPDATE_INIT';
export const DISPATCH_COMPLETION_UPDATE_SUCCESS = 'DISPATCH_COMPLETION_UPDATE_SUCCESS';
export const DISPATCH_COMPLETION_UPDATE_ERROR = 'DISPATCH_COMPLETION_UPDATE_ERROR';

export const FETCH_ADDEXPENSE_INIT = 'FETCH_ADDEXPENSE_INIT';
export const FETCH_ADDEXPENSE_SUCCESS = 'FETCH_ADDEXPENSE_SUCCESS';
export const FETCH_ADDEXPENSE_ERROR = 'FETCH_ADDEXPENSE_ERROR';

export const DELETE_EXPENSE_INIT = 'DELETE_EXPENSE_INIT';
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS';
export const DELETE_EXPENSE_ERROR = 'DELETE_EXPENSE_ERROR';

export const CREATE_DISPATCH_DOCUMENT= 'CREATE_DISPATCH_DOCUMENT';
export const CREATE_DISPATCH_DOCUMENT_SUCCESS = 'CREATE_DISPATCH_DOCUMENT_SUCCESS';
export const CREATE_DISPATCH_DOCUMENT_ERROR = 'CREATE_DISPATCH_DOCUMENT_ERROR';

export const FETCH_DISPATCH_DATA_INIT = 'FETCH_DISPATCH_DATA_INIT';
export const FETCH_DISPATCH_DATA_SUCCESS = 'FETCH_DISPATCH_DATA_SUCCESS';
export const FETCH_DISPATCH_DATA_ERROR = 'FETCH_DISPATCH_DATA_ERROR';


export const UPDATE_DISPATCH_DATA_INIT = 'UPDATE_DISPATCH_DATA_INIT';
export const UPDATE_DISPATCH_DATA_SUCCESS = 'UPDATE_DISPATCH_DATA_SUCCESS';
export const UPDATE_DISPATCH_DATA_ERROR = 'UPDATE_DISPATCH_DATA_ERROR';


export const UPDATE_TRAILER_DRIVER_INIT = 'UPDATE_TRAILER_DRIVER_INIT';
export const UPDATE_TRAILER_DRIVER_SUCCESS = 'UPDATE_TRAILER_DRIVER_SUCCESS';
export const UPDATE_TRAILER_DRIVER_ERROR = 'UPDATE_TRAILER_DRIVER_ERROR';



export const CLEAR_TRAILER ="CLEAR_TRAILER"

