import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import * as constant from "../constants/LivetrackingConstants";
import LivetrackingService from "../../services/LivetrackingService";

export function* FetchDispatchTrackingListSaga(payload) {
    try {
        const response = yield call(LivetrackingService.FetchDispatchTrackingApi, payload);
        yield put({ type: constant.FETCH_TRACKINGLIST_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_TRACKINGLIST_ERROR, error })
    }
}

export function* FetchAssignedRouteSaga(payload) {

    try {
        const response = yield call(LivetrackingService.FetchAssignedRoutesApi, payload);
        yield put({ type: constant.FETCH_ASSIGNEDROUTE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_ASSIGNEDROUTE_ERROR, error })
    }
}
export function* FetchTrackingOneSaga(payload) {
    try {
        const response = yield call(LivetrackingService.FetchTrackingOneApi, payload);
        yield put({ type: constant.FETCH_TRACKINGONE_SUCCESS, response })

        // Call Assigned Route Api
        // yield put({ type: constant.FETCH_ASSIGNEDROUTE_INIT, FetchAssignedRouteSaga })
    } catch (error) {
        yield put({ type: constant.FETCH_TRACKINGONE_ERROR, error })
    }
}
export function* FetchLiveRouteSaga(payload) {
    try {
        const response = yield call(LivetrackingService.FetchLiveRouteApi, payload);
        yield put({ type: constant.FETCH_LIVEROUTE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_LIVEROUTE_ERROR, error })
    }
}

export default function* LivetrackingSaga() {
    yield takeLatest(constant.FETCH_TRACKINGLIST_INIT, FetchDispatchTrackingListSaga);
    yield takeLatest(constant.FETCH_TRACKINGONE_INIT, FetchTrackingOneSaga);
    yield takeLatest(constant.FETCH_ASSIGNEDROUTE_INIT, FetchAssignedRouteSaga);
    yield takeEvery(constant.FETCH_LIVEROUTE_INIT, FetchLiveRouteSaga);
}