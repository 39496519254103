export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_ERROR = 'FETCH_EMPLOYEES_ERROR';

export const VIEW_EMPLOYEES = 'VIEW_EMPLOYEES';
export const VIEW_EMPLOYEES_SUCCESS = 'VIEW_EMPLOYEES_SUCCESS';
export const VIEW_EMPLOYEES_ERROR = 'VIEW_EMPLOYEES_ERROR';

export const CREATE_EMPLOYEES = 'CREATE_EMPLOYEES';
export const CREATE_EMPLOYEES_SUCCESS = 'CREATE_EMPLOYEES_SUCCESS';
export const CREATE_EMPLOYEES_ERROR = 'CREATE_EMPLOYEES_ERROR';

export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES';
export const DELETE_EMPLOYEES_SUCCESS = 'DELETE_EMPLOYEES_SUCCESS';
export const DELETE_EMPLOYEES_ERROR = 'DELETE_EMPLOYEES_ERROR';

export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES';
export const UPDATE_EMPLOYEES_SUCCESS = 'UPDATE_EMPLOYEES_SUCCESS';
export const UPDATE_EMPLOYEES_ERROR = 'UPDATE_EMPLOYEES_ERROR';

export const UPDATE_EMPLOYEE_STATUS = 'UPDATE_EMPLOYEE_STATUS';
export const UPDATE_EMPLOYEE_STATUS_SUCCESS = 'UPDATE_EMPLOYEE_STATUS_SUCCESS';
export const UPDATE_EMPLOYEE_STATUS_ERROR = 'UPDATE_EMPLOYEE_STATUS_ERROR';

export const CREATE_DOCUMENTEMPLOYEES = 'CREATE_DOCUMENTEMPLOYEES';
export const CREATE_DOCUMENTEMPLOYEES_SUCCESS = 'CREATE_DOCUMENTEMPLOYEES_SUCCESS';
export const CREATE_DOCUMENTEMPLOYEES_ERROR = 'CREATE_DOCUMENTEMPLOYEES_ERROR';

export const UPDATE_DOCUMENTEMPLOYEES = 'UPDATE_DOCUMENTEMPLOYEES';
export const UPDATE_DOCUMENTEMPLOYEES_SUCCESS = 'UPDATE_DOCUMENTEMPLOYEES_SUCCESS';
export const UPDATE_DOCUMENTEMPLOYEES_ERROR = 'UPDATE_DOCUMENTEMPLOYEES_ERROR';

export const DELETE_DOCUMENTEMPLOYEES = 'DELETE_DOCUMENTEMPLOYEES';
export const DELETE_DOCUMENTEMPLOYEES_SUCCESS = 'DELETE_DOCUMENTEMPLOYEES_SUCCESS';
export const DELETE_DOCUMENTEMPLOYEES_ERROR = 'DELETE_DOCUMENTEMPLOYEES_ERROR';

export const CREATEBTN_EMPLOYEES = 'CREATEBTN_EMPLOYEES';
export const CREATEBTN_EMPLOYEES_SUCCESS = 'CREATEBTN_EMPLOYEES_SUCCESS';
export const CREATEBTN_EMPLOYEES_ERROR = 'CREATEBTN_EMPLOYEES_ERROR';

export const SEARCH_EMPLOYEES = 'SEARCH_EMPLOYEES';
export const SEARCH_EMPLOYEES_SUCCESS = 'SEARCH_EMPLOYEES_SUCCESS';
export const SEARCH_EMPLOYEES_ERROR = 'SEARCH_EMPLOYEES_ERROR';

export const EMPLYOEE_DOCUMET_VIEW_INIT = 'EMPLYOEE_DOCUMET_VIEW_INIT';
export const EMPLYOEE_DOCUMET_VIEW_SUCCESS = 'EMPLYOEE_DOCUMET_VIEW_SUCCESS';
export const EMPLYOEE_DOCUMET_VIEW_ERROR = 'EMPLYOEE_DOCUMET_VIEW_ERROR';

export const EMPLYOEE_UPLOADED_DOCUMET_DELETE_INIT = 'EMPLYOEE_UPLOADED_DOCUMET_DELETE_INIT';
export const EMPLYOEE_UPLOADED_DOCUMET_DELETE_SUCCESS = 'EMPLYOEE_UPLOADED_DOCUMET_DELETE_SUCCESS';
export const EMPLYOEE_UPLOADED_DOCUMET_DELETE_ERROR = 'EMPLYOEE_UPLOADED_DOCUMET_DELETE_ERROR';

export const VIEW_EXPIRY_EMPLOYEES = 'VIEW_EXPIRY_EMPLOYEES';
export const VIEW_EXPIRY_EMPLOYEES_SUCCESS = 'VIEW_EXPIRY_EMPLOYEES_SUCCESS';
export const VIEW_EXPIRY_EMPLOYEES_ERROR = 'VIEW_EXPIRY_EMPLOYEES_ERROR';

export const FETCH_EXPIRY_EMPLOYEES = 'FETCH_EXPIRY_EMPLOYEES';
export const FETCH_EXPIRY_EMPLOYEES_SUCCESS = 'FETCH_EXPIRY_EMPLOYEES_SUCCESS';
export const FETCH_EXPIRY_EMPLOYEES_ERROR = 'FETCH_EXPIRY_EMPLOYEES_ERROR';

export const CREATEUPDATE_EXPIRY_EMPLOYEES = 'CREATEUPDATE_EXPIRY_EMPLOYEES';
export const CREATEUPDATE_EXPIRY_EMPLOYEES_SUCCESS = 'CREATEUPDATE_EXPIRY_EMPLOYEES_SUCCESS';
export const CREATEUPDATE_EXPIRY_EMPLOYEES_ERROR = 'CREATEUPDATE_EXPIRY_EMPLOYEES_ERROR';

export const FETCH_NOTIFY_EMPLOYEES = 'FETCH_NOTIFY_EMPLOYEES';
export const FETCH_NOTIFY_EMPLOYEES_SUCCESS = 'FETCH_NOTIFY_EMPLOYEES_SUCCESS';
export const FETCH_NOTIFY_EMPLOYEES_ERROR = 'FETCH_NOTIFY_EMPLOYEES_ERROR';

export const LOGOUT_DEVICE_EMPLOYEES_INIT = 'LOGOUT_DEVICE_EMPLOYEES';
export const LOGOUT_DEVICE_EMPLOYEES_SUCCESS = 'LOGOUT_DEVICE_EMPLOYEES_SUCCESS';
export const LOGOUT_DEVICE_EMPLOYEES_ERROR = 'LOGOUT_DEVICE_EMPLOYEES_ERROR';
