
import { API_BASE_URL } from 'configs/AppConfig'

const LoginAuthService = {}

LoginAuthService.login = function (email, password) {
    const login_api_url = API_BASE_URL + `/master/login`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    };

    return fetch(login_api_url, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
LoginAuthService.superlogin = function (email, password) {
    const login_api_url = API_BASE_URL + `/admin/login`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    };

    return fetch(login_api_url, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
LoginAuthService.logout = function () {
    return "undefined";
}

export default LoginAuthService