

export const VIEW_LATESTORDER = 'VIEW_LATESTORDER';
export const VIEW_LATESTORDER_SUCCESS = 'VIEW_LATESTORDER_SUCCESS';
export const VIEW_LATESTORDER_ERROR = 'VIEW_LATESTORDER_ERROR';

export const VIEW_LATESTEMPLOYEE = 'VIEW_LATESTEMPLOYEE';
export const VIEW_LATESTEMPLOYEE_SUCCESS = 'VIEW_LATESTEMPLOYEE_SUCCESS';
export const VIEW_LATESTEMPLOYEE_ERROR = 'VIEW_LATESTEMPLOYEE_ERROR';



