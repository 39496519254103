import * as constant from "../constants/CompanyConstants";

const initialState = {
    company_list: [],
    company: null,
    loading: false,
    error: null,
    companystatus_change: false,
    create_success: false,
    update_success: false,
    delete_success: false,
    single_company: null,
    viewcompany: null,
    companyupdate: false,
};

const CompanyReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_COMPANY:
        case constant.CREATE_COMPANY:
        case constant.COMPANY_STATUS_CHANGE:
        case constant.EDIT_COMPANY:
        case constant.VIEW_COMPANY:
        case constant.DELETE_COMPANY:
            return {
                ...state,
                loading: true,
                create_success: false,
                update_success: false,
                delete_success: false,
            }
        case constant.FETCH_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company_list: response.data,
            }
        case constant.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company: response,
                create_success: response,
            }
        case constant.COMPANY_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                companystatus_change: response,
            }
        case constant.EDIT_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                companyupdate: response,
                update_success: response,
            };
        case constant.VIEW_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                viewcompany: response.data,
                single_company: response.data,
            };
        case constant.DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };

        case constant.FETCH_COMPANY_ERROR:
        case constant.CREATE_COMPANY_ERROR:
        case constant.COMPANY_STATUS_CHANGE_ERROR:
        case constant.EDIT_COMPANY_ERROR:
        case constant.VIEW_COMPANY_ERROR:
        case constant.DELETE_COMPANY_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            }
        default:
            return state;
    }
};

export default CompanyReducer;
