import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/ShipmentConstants";
import ShipmentService from "../../services/ShipmentService";

export function* FetchShipmentSaga(payload) {
    try {
        const response = yield call(ShipmentService.FetchShipmentInit, payload);
        yield put({ type: constant.FETCH_SHIPMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_SHIPMENT_ERROR, error })
    }
}

export function* ViewShipmentSaga(shipId) {
    try {
        const response = yield call(ShipmentService.ViewOrderShipment, shipId);
        yield put({ type: constant.VIEW_SHIPMENT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_SHIPMENT_ERROR, error })
    }
}

export default function* ShipmentSaga() {
    yield takeLatest(constant.FETCH_SHIPMENT_INIT, FetchShipmentSaga);
    yield takeLatest(constant.VIEW_SHIPMENT_INIT, ViewShipmentSaga);
}