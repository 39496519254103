import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/QuoteConstants";
import QuoteService from "../../services/QuoteService";

export function* FetchQuotesSaga(payload) {
    try {
        const response = yield call(QuoteService.FetchQuotes, payload);
        yield put({ type: constant.FETCH_QUOTES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_QUOTES_ERROR, error })
    }
}
export function* FetchQoutesCountSaga(payload) {
    try {
        const response = yield call(QuoteService.FetchQuotesCounts, payload);
        yield put({ type: constant.FETCH_QUOTES_COUNT_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_QUOTES_COUNT_ERROR, error })
    }
}
export function* CreateQuoteSaga(payload) {
    try {
        const response = yield call(QuoteService.CreateQuote, payload.formData);
        yield put({ type: constant.CREATE_QUOTE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_QUOTE_ERROR, error })
    }
}
export function* UploadPodSaga(payload) {
    try {
        const response = yield call(QuoteService.UploadPODDoc, payload.formData);
        yield put({ type: constant.UPLOAD_POD_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPLOAD_POD_ERROR, error })
    }
}
export function* UpdateQuoteSaga(payload) {
    try {
        const response = yield call(QuoteService.UpdateQuote, payload.formData);
        yield put({ type: constant.UPDATE_QUOTE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_QUOTE_ERROR, error })
    }
}
export function* ViewQuoteSaga(quotationId) {
    try {
        const response = yield call(QuoteService.ViewQuote, quotationId);
        yield put({ type: constant.VIEW_QUOTE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_QUOTE_ERROR, error })
    }
}
export function* DeleteQuoteSaga(quotationId) {
    try {
        const response = yield call(QuoteService.DeleteQuote, quotationId);
        yield put({ type: constant.DELETE_QUOTE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_QUOTE_ERROR, error })
    }
}

export function* DeletePODSaga(quotationId) {
    try {
        const response = yield call(QuoteService.DeletePOD, quotationId);
        yield put({ type: constant.DELETE_POD_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_POD_ERROR, error })
    }
}

export function* MoveQuoteSaga(payload) {
    try {
        const response = yield call(QuoteService.MoveQuote, payload.quotationId);
        yield put({ type: constant.MOVE_TO_ORDER_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.MOVE_TO_ORDER_ERROR, error })
    }
}
export function* MoveQuoteAgainSaga(payload) {
    try {
        const response = yield call(QuoteService.MoveQuoteAgain, payload.quotationId, payload.formData);
        yield put({ type: constant.MOVE_AGAIN_TO_ORDER_SUCCESS, response })

        // Reload Quotation list after quotation moved
        yield put({ type: constant.FETCH_QUOTES_INIT, FetchQuotesSaga })
    } catch (error) {
        yield put({ type: constant.MOVE_AGAIN_TO_ORDER_ERROR, error })
    }
}

export function* QuoteMailSendSage(payload) {
    try {
        const response = yield call(QuoteService.QuoteMailSend, payload.quotationId);
        yield put({ type: constant.QUOTE_MAILSEND_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.QUOTE_MAILSEND_ERROR, error })
    }
}

export default function* VendorSaga() {
    yield takeLatest(constant.FETCH_QUOTES_INIT, FetchQuotesSaga);
    yield takeLatest(constant.CREATE_QUOTE_INIT, CreateQuoteSaga);
    yield takeLatest(constant.UPLOAD_POD_INIT, UploadPodSaga);
    yield takeLatest(constant.UPDATE_QUOTE_INIT, UpdateQuoteSaga);
    yield takeLatest(constant.FETCH_QUOTES_COUNT_INIT, FetchQoutesCountSaga);
    yield takeLatest(constant.VIEW_QUOTE_INIT, ViewQuoteSaga);
    yield takeLatest(constant.DELETE_QUOTE_INIT, DeleteQuoteSaga);
    yield takeLatest(constant.DELETE_POD_INIT, DeletePODSaga);
    yield takeLatest(constant.MOVE_TO_ORDER, MoveQuoteSaga);
    yield takeLatest(constant.MOVE_AGAIN_TO_ORDER, MoveQuoteAgainSaga);
    yield takeLatest(constant.QUOTE_MAILSEND_INIT, QuoteMailSendSage);

}