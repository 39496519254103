import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/EquipmentConstants";
import EquipmentService from "../../services/EquipmentService";

export function* FetchEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.FetchEquipments, payload);
        yield put({ type: constant.FETCH_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.FETCH_EQUIPMENTS_ERROR, error });
    }
}
export function* CreateEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.CreateEquipments, payload.data);
        yield put({ type: constant.CREATE_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATE_EQUIPMENTS_ERROR, error });
    }
}
export function* ViewEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.ViewEquipments, payload.id);
        yield put({ type: constant.VIEW_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.VIEW_EQUIPMENTS_ERROR, error });
    }
}
export function* UpdateEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.UpdateEquipments, payload.data);
        yield put({ type: constant.UPDATE_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.UPDATE_EQUIPMENTS_ERROR, error });
    }
}
export function* UpdateEquipmentStatusSaga(payload) {

    try {
        const response = yield call(EquipmentService.UpdateEquipmentsStatus, payload.formData);
        yield put({ type: constant.UPDATE_EQUIPMENTS_STATUS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.UPDATE_EQUIPMENTS_STATUS_ERROR, error });
    }
}

export function* DeleteEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.DeleteEquipments, payload.id);
        yield put({ type: constant.DELETE_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.DELETE_EQUIPMENTS_ERROR, error });
    }
}
export function* CreateServiceEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.CreateServiceEquipments, payload.data);
        yield put({ type: constant.CREATE_SERVICEEQUIPMENTS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_SERVICEEQUIPMENTS_ERROR, error })
    }
}
export function* UpdateServiceEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.UpdateServiceEquipments, payload.formData);
        yield put({ type: constant.UPDATE_SERVICEEQUIPMENTS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_SERVICEEQUIPMENTS_ERROR, error })
    }
}
export function* DeleteServiceEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.DeleteServiceEquipments, payload.id);
        yield put({ type: constant.DELETE_SERVICEEQUIPMENTS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_SERVICEEQUIPMENTS_ERROR, error })
    }
}
export function* FetchEquipmentStatus(payload) {
    try {
        const response = yield call(EquipmentService.FetchEquipmentStatus, payload);
        yield put({ type: constant.FETCH_EQUIPMENTS_STATUS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_EQUIPMENTS_STATUS_ERROR, error })
    }
}
export function* FetchEquipmentBtnSaga() {
    try {
        const response = yield call(EquipmentService.CreateBtnEquipments);
        yield put({ type: constant.CREATEBTN_EQUIPMENTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATEBTN_EQUIPMENTS_ERROR, error });
    }
}
export function* searchEquipmentSaga(payload) {
    try {
        const response = yield call(EquipmentService.SearchEquipments, payload);
        yield put({ type: constant.SEARCH_EQUIPMENTS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.SEARCH_EQUIPMENTS_ERROR, error })
    }
}
export function* EquipmentstatusSingleview(payload) {
    try {
        const response = yield call(EquipmentService.FetchEquipmentstatusSingleview, payload);
        yield put({ type: constant.EQUIPMENTSTATUS_SINGLEVIEW_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.EQUIPMENTSTATUS_SINGLEVIEW_ERROR, error })
    }
}
export function* FetchEquipmentExpiryDataSaga(payload) {
    try {
        const response = yield call(EquipmentService.FetchEquipmentExpiryData, payload);
        yield put({ type: constant.FETCH_EQUIPMENT_EXPIRY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_EQUIPMENT_EXPIRY_ERROR, error })
    }
}
export default function* EquipmentSaga() {
    yield takeLatest(constant.FETCH_EQUIPMENTS, FetchEquipmentSaga);
    yield takeLatest(constant.CREATE_EQUIPMENTS, CreateEquipmentSaga);
    yield takeLatest(constant.VIEW_EQUIPMENTS, ViewEquipmentSaga);
    yield takeLatest(constant.UPDATE_EQUIPMENTS, UpdateEquipmentSaga);
    yield takeLatest(constant.DELETE_EQUIPMENTS, DeleteEquipmentSaga);
    yield takeLatest(constant.CREATE_SERVICEEQUIPMENTS, CreateServiceEquipmentSaga);
    yield takeLatest(constant.UPDATE_SERVICEEQUIPMENTS, UpdateServiceEquipmentSaga);
    yield takeLatest(constant.DELETE_SERVICEEQUIPMENTS, DeleteServiceEquipmentSaga);
    yield takeLatest(constant.FETCH_EQUIPMENTS_STATUS, FetchEquipmentStatus);
    yield takeLatest(constant.FETCH_EQUIPMENTS, FetchEquipmentBtnSaga);
    yield takeLatest(constant.SEARCH_EQUIPMENTS, searchEquipmentSaga);
    yield takeLatest(constant.EQUIPMENTSTATUS_SINGLEVIEW, EquipmentstatusSingleview);
    yield takeLatest(constant.UPDATE_EQUIPMENTS_STATUS, UpdateEquipmentStatusSaga);
    yield takeLatest(constant.FETCH_EQUIPMENT_EXPIRY, FetchEquipmentExpiryDataSaga);
}
