import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_ID,
	AUTH_TOKEN,
	AUTH_NAME,
	SIGNIN,
	SUPERSIGNIN,
	SIGNOUT,
	AUTH_ROLE,
	AUTH_LOGO,
	AUTH_ROL
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signInSuccess,
	superSignInSuccess
} from "../actions/Auth";
import jwt_decode from "jwt-decode";
import LoginAuthService from 'services/AuthService';

export function* signInWithCredentials() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const user = yield call(LoginAuthService.login, email, password);
			if (user.hasOwnProperty('error')) { // TEMP Solution (SELVAN ADDED)
				yield put(showAuthMessage(user.error.common));
			} else if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				let role = 'driver';
				localStorage.setItem(AUTH_ID, user.employee.id);
				localStorage.setItem(AUTH_TOKEN, user.token);
				localStorage.setItem(AUTH_ROLE, role);
				localStorage.setItem(AUTH_NAME, user.company.name);
				localStorage.setItem("companyProfileLogo", user.company.logo);
				// yield put(authenticated(user.token, "driver"));
				yield put(signInSuccess(user.token, 'driver'));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}
export function* signInWithCredentialsForSuperadmin() {

	yield takeEvery(SUPERSIGNIN, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const user = yield call(LoginAuthService.superlogin, email, password);
			if (user.error) {
				yield put(showAuthMessage(user.error.common));
			}
			if (user.message) {
				yield put(showAuthMessage(user.message));
			}
			else {
				let name = user.admin ? user.admin.name : 'SuperAdmin';

				let role = 'super-admin';
				localStorage.setItem(AUTH_TOKEN, user.token);
				localStorage.setItem(AUTH_ROLE, role);
				localStorage.setItem(AUTH_NAME, name);
				//yield put(authenticated(user.token, "super-admin"));
				yield put(superSignInSuccess(user.token, 'super-admin'));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {

	yield takeEvery(SIGNOUT, function* () {
		try {
			var token = localStorage.getItem(AUTH_TOKEN)
			if (token) {
				var decode = jwt_decode(token)
				var authrole = decode.iss.includes("admin");
				localStorage.setItem(AUTH_ROL, decode.iss);
			}
			const signOutUser = yield call(LoginAuthService.logout);
			if (signOutUser === "undefined") {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem(AUTH_ROLE);
				localStorage.removeItem(AUTH_ID);
				localStorage.removeItem(AUTH_NAME);
				localStorage.removeItem(AUTH_LOGO);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithCredentials),
		fork(signInWithCredentialsForSuperadmin),
		fork(signOut)
	]);
}
