import fetch from 'auth/FetchInterceptor';

const DispatchService = {}

DispatchService.FetchDispatches = function (paginationParams) {
   
    return fetch({
        url: `/dispatch-list?status=${paginationParams.data.status}&per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
        method: 'get'
    })
}

DispatchService.FetchDispatchCounts = function (paginationParams) {
    return fetch({
        url: `/dispatch-status-count`,
        method: 'get'
    })
}

DispatchService.ViewDispatch = function (data) {
   
    return fetch({
        url: '/dispatch-view/' + data?.dispatchId,
        method: 'get'
    })
}
DispatchService.DeleteDispatch = function (dispatchId) {
    return fetch({
        url: '/dispatch-delete/' + dispatchId,
        method: 'delete',
    })
}
DispatchService.DeleteExpense = function (payload) {
    return fetch({
        url: '/dispatch-expenses-delete/' + payload.expenseId,
        method: 'delete',
    })
}
DispatchService.OrderUpdate = function (formData) {
    return fetch({
        url: '/dispatch/' + formData.dispatchId + '/order/' + formData.orderId,
        method: 'put',
        data: {
            status: formData.status
        }
    })
}
DispatchService.ShipmentUpdate = function (formData) {
    return fetch({
        url: '/dispatch/' + formData.dispatchId + '/order/' + formData.orderId + '/shipment/' + formData.shipmentId,
        method: 'post',
        data: {
            status: formData.status,
            total_miles: formData.total_miles
        }
    })
}


DispatchService.CreateDispatchContact = function (formData) {
    return fetch({
        url: '/create-dispatcher',
        method: 'post',
        data: formData
    })
}

DispatchService.CreateAccountRep = function (formData) {
    return fetch({
        url: '/create-accountrep',
        method: 'post',
        data: formData
    })
}

DispatchService.CreateSalesRep = function (formData) {
    return fetch({
        url: '/create-salesrep',
        method: 'post',
        data: formData
    })
}

DispatchService.DispatchCompletionUpdate = function (formData) {
    return fetch({
        url: '/dispatch-status/' + formData.dispatchId,
        method: 'put',
        data: formData.data,
    })
}

DispatchService.CreateExpense = function (formData) {
    return fetch({
        url: '/dispatch-expenses-create',
        method: 'post',
        data: formData.data
    })
}
DispatchService.CreateDispatchdocument = function (data) {

    return fetch({
        url: '/dispatch-document',
        method: 'post',
        data: data
    })
}
DispatchService.FetchDispatchData = function (id) {

    return fetch({

        url: "/mobile-dispatch-view/" + id.dispatchId,
        method: 'get'
    })
}
DispatchService.UpdateDispatchData = function (payload) {
    return fetch({
        url: "/move-to-existing-dispatch",
        method: 'post',
        data: payload.formData
    })
}

DispatchService.UpdateDriverTrailerData = function (formData) {
    console.log(formData);
    return fetch({
        url: "/driver-trailer-update",
        method: 'post',
        data: formData
    })
}
export default DispatchService

