import * as constant from "../constants/EssentialConstants";

const initialState = {
    essential_list: [],
    essentialdispatchorder_list: [],
    essentialcountry_list:[],
    loading: false,
    error: null,
};

const EssentialReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_ESSENTIAL:
        case constant.FETCH_ESSENTIAL_DISPATCHORDER:
        case constant.FETCH_ESSENTIAL_COUNTRY:
            return {
                ...state,
                loading: true,
            };
           
        case constant.FETCH_ESSENTIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                essential_list: response.data,
            };
        case constant.FETCH_ESSENTIAL_DISPATCHORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                essentialdispatchorder_list: response.data,
            };



            case constant.FETCH_ESSENTIAL_COUNTRY_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    essentialcountry_list: response,
                };

        case constant.FETCH_ESSENTIAL_ERROR:
        case constant.FETCH_ESSENTIAL_DISPATCHORDER_ERROR:
        case constant.FETCH_ESSENTIAL_COUNTRY_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};
export default EssentialReducer;