export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR';

export const COMPANY_STATUS_CHANGE = 'COMPANY_STATUS_CHANGE';
export const COMPANY_STATUS_CHANGE_SUCCESS = 'COMPANY_STATUS_CHANGE_SUCCESS';
export const COMPANY_STATUS_CHANGE_ERROR = 'COMPANY_STATUS_CHANGE_ERROR';

export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';

export const VIEW_COMPANY = 'VIEW_COMPANY';
export const VIEW_COMPANY_SUCCESS = 'VIEW_COMPANY_SUCCESS';
export const VIEW_COMPANY_ERROR = 'VIEW_COMPANY_ERROR';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';