import * as constant from "../constants/ExpenseConstants";

const initialState = {
    loading: false,
    error: null,
    vendorPay: [],
    employeePay: [],
    owneroperatorPay: null,
    vendorPaySuccess: null,
    employeePaySuccess: null,
    operatorPaySuccess: [],
    paidList: [],
    singlepaylist: [],
    expensemailsendsuccess: null
};

const ExpenseReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_VENDOR_PAYLIST:
        case constant.FETCH_EMPLOYEE_PAYLIST:
        case constant.FETCH_OWNEROPERATOR_PAYLIST:
        case constant.PAY_VENDOR:
        case constant.PAY_EMPLOYEE:
        case constant.PAY_OWNEROPERATOR:
        case constant.PAID_LIST:
        case constant.SINGLE_PAID_LIST:
        case constant.EXPENSEPDF_EMAIL:
            return {
                ...state,
                loading: true,
            };

        case constant.FETCH_VENDOR_PAYLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                vendorPay: response.data,
            };

        case constant.FETCH_EMPLOYEE_PAYLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                employeePay: response.data,
            };

        case constant.FETCH_OWNEROPERATOR_PAYLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                owneroperatorPay: response.data,
            };

        case constant.PAY_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                vendorPaySuccess: response,
            };

        case constant.PAY_EMPLOYEE_SUCCESS:
            return {
                ...state,
                loading: false,
                employeePaySuccess: response,
            };

        case constant.PAY_OWNEROPERATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                operatorPaySuccess: response,
            };

        case constant.PAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                paidList: response.data,
            };
        case constant.SINGLE_PAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                singlepaylist: response.data,
            };

        case constant.EXPENSEPDF_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                expensemailsendsuccess: response,
            };

        case constant.FETCH_VENDOR_PAYLIST_ERROR:
        case constant.FETCH_EMPLOYEE_PAYLIST_ERROR:
        case constant.FETCH_OWNEROPERATOR_PAYLIST_ERROR:
        case constant.PAY_VENDOR_ERROR:
        case constant.PAY_EMPLOYEE_ERROR:
        case constant.PAY_OWNEROPERATOR_ERROR:
        case constant.PAID_LIST_ERROR:
        case constant.SINGLE_PAID_LIST_ERROR:
        case constant.EXPENSEPDF_EMAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};

export default ExpenseReducer;
