import fetch from 'auth/FetchInterceptor';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const EssentialService = {}

EssentialService.FetchEssential = function (data) {
    let dynaUrl = APP_PREFIX_PATH === "/driver" ? '/essential-list?select=' + data : '/essential-lists?select=' + data;
    return fetch({
        url: dynaUrl,
        method: 'get',
        data: data,
    })
}

EssentialService.FetchEssentialDispatchOrder = function (id) {
    return fetch({
        url: '/dispatch-order-data',
        method: 'post',
        data: { 'id': id.id }
    })
}

EssentialService.FetchEssentialCountry = function () {
    return fetch({
        url: 'https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=8.186480,77.430923&radius=5000&types=gas_station&rankBy=distance&sensor=true&key=AIzaSyBpPnB6Le56oWSrap61sQsrZY3HRtr_cQU',
        method: 'get',
        
    })
}

export default EssentialService

