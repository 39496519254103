import fetch from 'auth/FetchInterceptor';

const EquipmentService = {}

EquipmentService.FetchEquipments = function (paginationParams) {
    return fetch({
        url: `/equipment-list?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
        method: 'get',
    })
}
EquipmentService.ViewEquipments = function (id) {
    return fetch({
        url: `/equipment-view/${id}`,
        method: 'get',
    })
}
EquipmentService.DeleteEquipments = function (id) {
    return fetch({
        url: `/equipment-delete/${id}`,
        method: 'delete',
    })
}
EquipmentService.CreateEquipments = function (data) {
    return fetch({
        url: '/equipment-create',
        method: 'post',
        data: data
    })
}
EquipmentService.UpdateEquipments = function (data) {
    return fetch({
        url: '/equipment-update/' + data.id,
        method: 'put',
        data: data
    })
}
EquipmentService.UpdateEquipmentsStatus = function (data) {
    return fetch({
        url: '/equipment-status/' + data.id,
        method: 'put',
        data: data
    })
}
EquipmentService.CreateServiceEquipments = function (data) {
    return fetch({
        url: '/equipment-services-create',
        method: 'post',
        data: data
    })
}
EquipmentService.UpdateServiceEquipments = function (formData) {
    return fetch({
        url: '/equipment-services-update/' + formData.updateid,
        method: 'put',
        data: formData.formdata
    })
}
EquipmentService.DeleteServiceEquipments = function (id) {
    return fetch({
        url: '/equipment-services-delete/' + id,
        method: 'delete',
    })
}
EquipmentService.FetchEquipmentStatus = function (filterParams) {
    return fetch({
        url: '/equipment-status',
        method: 'post',
        data: filterParams.data
    })
}
EquipmentService.CreateBtnEquipments = function () {
    return null
}
EquipmentService.SearchEquipments = function (data) {
    if (data.data.searchinternal === "unit_no") {
        return fetch({
            url: '/equipment-list?unit_no=' + data.data.filterdata,
            method: 'get'
        })
    }
    if (data.data.searchinternal === "unit_type") {
        return fetch({
            url: '/equipment-list?unit_type=' + data.data.filterdata,
            method: 'get'
        })
    }
    if (data.data.searchinternal === "license_plate") {
        return fetch({
            url: '/equipment-list?license_plate=' + data.data.filterdata,
            method: 'get'
        })
    }
}
EquipmentService.FetchEquipmentstatusSingleview = function (id) {
    return fetch({
        url: '/equipment-status-view/' + id.id.id,
        method: 'get',
    })
}
EquipmentService.FetchEquipmentExpiryData = function (paginationParams) {
    return fetch({
        url: `/equipment-expiry`,
        method: 'get',
    })
}
export default EquipmentService