import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/EssentialConstants";
import EssentialService from "../../services/EssentialService";

export function* FetchEssentialsSaga(payload) {
    try {
        const response = yield call(EssentialService.FetchEssential, payload.data);
        yield put({ type: constant.FETCH_ESSENTIAL_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_ESSENTIAL_ERROR, error })
    }
}
export function* FetchEssentialCountry(payload) {
    try {
        const response = yield call(EssentialService.FetchEssentialCountry,payload );
        yield put({ type: constant.FETCH_ESSENTIAL_COUNTRY_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_ESSENTIAL_COUNTRY_ERROR, error })
    }
}
export function* FetchEssentialDispatchOrdersSaga(payload) {
    try {
        const response = yield call(EssentialService.FetchEssentialDispatchOrder, payload);
        yield put({ type: constant.FETCH_ESSENTIAL_DISPATCHORDER_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_ESSENTIAL_DISPATCHORDER_ERROR, error })
    }
}

export default function* EssentialSaga() {
    yield takeLatest(constant.FETCH_ESSENTIAL, FetchEssentialsSaga);
    yield takeLatest(constant.FETCH_ESSENTIAL_DISPATCHORDER, FetchEssentialDispatchOrdersSaga);
    yield takeLatest(constant.FETCH_ESSENTIAL_COUNTRY, FetchEssentialCountry);
}