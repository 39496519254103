import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNIN,
	SIGNIN_SUCCESS,
	SUPERSIGNIN,
	SUPERSIGNIN_SUCCESS,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SIGNUP_ERROR,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	AUTH_ROLE
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	role: localStorage.getItem(AUTH_ROLE)
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: action.role === "super-admin" ? '/' + action.role + '/company/list' : '/' + action.role + '/dashboard',
				token: action.token
			}

		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNIN: {
			// return {
			// 	...state,
			// 	token: null,
			// 	// redirect: '/' + action.role + '/dashboard',
			// }
		}
		case SIGNIN_SUCCESS: {
			return {
				...state,
				token: action.token,
				redirect: '/' + action.role + '/dashboard',
				loading: false,
				role: action.role
			}
		}
		case SUPERSIGNIN: {
			// return {
			// 	...state,
			// 	token: null,
			// 	// redirect: '/' + action.role + '/company/list',
			// 	// loading: false
			// }
		}
		case SUPERSIGNIN_SUCCESS: {
			return {
				...state,
				token: action.token,
				redirect: '/' + action.role + '/company/list',
				loading: false,
				role: action.role
			}
		}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: action.token,
				redirect: '/',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNUP_ERROR: {
			return {
				...state,
				loading: false,
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}

		default:
			return state;
	}
}

export default auth