import * as constant from "../constants/EmployeeConstants";

const initialState = {

    employee_list: [],
    loading: false,
    error: null,
    data: null,
    new_document: null,
    single_employee: null,
    delete_success: null,
    document_created: null,
    emp_document_data: null,
    delete_doc_success: null,
    uploaded_doc_deleted: null,
    update_emp_doc: null,
    single_expiryemployee: null,
    employee_expirylist: null,
    employee_notifylist: null,
    logout_device: null,
    update_status_data: null
};

const EmployeeReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_EMPLOYEES:
        case constant.VIEW_EMPLOYEES:
        case constant.CREATE_EMPLOYEES:
        case constant.DELETE_EMPLOYEES:
        case constant.UPDATE_EMPLOYEES:
        case constant.UPDATE_EMPLOYEE_STATUS:
        case constant.CREATE_DOCUMENTEMPLOYEES:
        case constant.UPDATE_DOCUMENTEMPLOYEES:
        case constant.CREATEBTN_EMPLOYEES:
        case constant.EMPLYOEE_DOCUMET_VIEW_INIT:
        case constant.DELETE_DOCUMENTEMPLOYEES:
        case constant.EMPLYOEE_UPLOADED_DOCUMET_DELETE_INIT:
        case constant.VIEW_EXPIRY_EMPLOYEES:
        case constant.FETCH_EXPIRY_EMPLOYEES:
        case constant.CREATEUPDATE_EXPIRY_EMPLOYEES:
        case constant.FETCH_NOTIFY_EMPLOYEES:
            return {
                ...state,
                loading:true
            };

        case constant.LOGOUT_DEVICE_EMPLOYEES_INIT:
            return {
                ...state,
                loading: true,
            };

        case constant.FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                employee_list: response.data,
            };

        case constant.EMPLYOEE_DOCUMET_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                emp_document_data: response,
            };

        case constant.VIEW_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                single_employee: response.data,
            };

        case constant.DELETE_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response
            };
        case constant.UPDATE_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response,
            };
        case constant.UPDATE_EMPLOYEE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                update_status_data: response,
            };

        case constant.EMPLYOEE_UPLOADED_DOCUMET_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                uploaded_doc_deleted: response
            };
        case constant.CREATE_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response,
            };
        case constant.CREATE_DOCUMENTEMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                new_document: response.data,
                document_created: response
            };
        case constant.UPDATE_DOCUMENTEMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                update_emp_doc: response.data,
            };
        case constant.DELETE_DOCUMENTEMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_doc_success: response
            };
        case constant.CREATEBTN_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                single_employee: null,
            };
        case constant.SEARCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                employee_list: response.data,
            };
        case constant.CREATEUPDATE_EXPIRY_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response,
            };
        case constant.FETCH_EXPIRY_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                employee_expirylist: response.data,
            };

        case constant.VIEW_EXPIRY_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                single_expiryemployee: response.data,
            };

        case constant.FETCH_NOTIFY_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                employee_notifylist: response.data,
            };
        case constant.LOGOUT_DEVICE_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                logout_data: response,
            };
        case constant.FETCH_EMPLOYEES_ERROR:
        case constant.VIEW_EMPLOYEES_ERROR:
        case constant.CREATE_EMPLOYEES_ERROR:
        case constant.DELETE_EMPLOYEES_ERROR:
        case constant.UPDATE_EMPLOYEES_ERROR:
        case constant.UPDATE_EMPLOYEE_STATUS_ERROR:
        case constant.CREATE_DOCUMENTEMPLOYEES_ERROR:
        case constant.UPDATE_DOCUMENTEMPLOYEES_ERROR:
        case constant.DELETE_DOCUMENTEMPLOYEES_ERROR:
        case constant.CREATEBTN_EMPLOYEES_ERROR:
        case constant.EMPLYOEE_DOCUMET_VIEW_ERROR:
        case constant.EMPLYOEE_UPLOADED_DOCUMET_DELETE_ERROR:
        case constant.VIEW_EXPIRY_EMPLOYEES_ERROR:
        case constant.FETCH_EXPIRY_EMPLOYEES_ERROR:
        case constant.CREATEUPDATE_EXPIRY_EMPLOYEES_ERROR:
        case constant.FETCH_NOTIFY_EMPLOYEES_ERROR:
        case constant.UPDATE_EMPLOYEE_STATUS_ERROR:

        case constant.LOGOUT_DEVICE_EMPLOYEES_ERROR:

            return {
                ...state,
                loading: false,
                error: response,
            };

        default:
            return state;
    }
};
export default EmployeeReducer;