import { notification } from 'antd';
import fetch from 'auth/FetchInterceptor';

const RevenueService = {}

RevenueService.FetchPostInvoice = function (paginationParams) {
    return fetch({
        url: `/invoice-list?status=${paginationParams.data.status}&per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}&customer_id=${paginationParams.data.customer_id}&from_date=${paginationParams.data.fromDate}&to_date=${paginationParams.data.toDate}`,
        method: 'get'
    })
}

RevenueService.FetchReceivable = function (paginationParams) {
    return fetch({
        url: `/receivable-list?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&date=${paginationParams.data.date}&search=${paginationParams.data.search}`,
        method: 'get'
    })
}
RevenueService.MoveReceivable = function (formData) {
    return fetch({
        url: '/invoice-customer-pay',
        method: 'post',
        data: formData.formData
    })
}


RevenueService.FetchCustomerPays = function (payload) {
    let url = "/invoice-list";

    if (payload.customerId !== '' && payload.fromDate !== '' && payload.toDate !== '') {
        url = "/invoice-list?customer_id=" + payload.customerId + "&from_date=" + payload.fromDate + "&to_date=" + payload.toDate;
    }
    else if (payload.customerId !== '') {
        url = "/invoice-list?customer_id=" + payload.customerId;
    }
    return fetch({
        url: url,
        method: 'get'
    })
}


RevenueService.FetchOrderPdf = function (invoiceId) {
    return fetch({
        url: '/order/invoice/' + invoiceId.invoiceId,
        method: 'get',
    })
}

// RevenueService.FetchOrderEmail = function (invoiceId) {
//     return fetch({
//         url: '/send-invoice-PDF/' + invoiceId.invoiceId,
//         method: 'get',
//     })
// }
RevenueService.FetchOrderEmail = function (invoiceId) {
    let endPoint  =""
    let method  = ""
    if(invoiceId.invoiceId.type=="bill"){
        endPoint = "/billing/mail/"
    }
    if(invoiceId.invoiceId.type=="invoice"){
        endPoint = '/send-invoice/'

    }
    return fetch({
        url: endPoint + invoiceId.invoiceId.id,
        method: 'POST',
        data: invoiceId.invoiceId.values
    }).then((data)=>{notification.success({message:data?.message}); invoiceId.invoiceId?.setEmailPopup(false)})
}

RevenueService.FetchReceivableSingleView = function (data) {
   
    return fetch({
        url: '/receivable-view/' + data.id,
        method: 'get',
    })
}

RevenueService.FetchRevenueEmail = function (invoiceData) {
    return fetch({
        url: '/receivable-email/' + invoiceData.invoiceId.id,
        method: 'post',
        data: invoiceData.invoiceId.values
    })
}

RevenueService.FetchReceivableAdd = function (formData) {
    return fetch({
        url: '/order-revenues-create',
        method: 'post',
        data: formData
    })
}

RevenueService.DeleteRevenues = function (id,status) {
 
    return fetch({
       
        url: '/order-revenues-delete/' + id.id +'/'+ id.status,
        method: 'delete',
    })
}

RevenueService.UpdateRevenues = function (formData,id) {

console.log(id);

console.log(formData);
    return fetch({
       
        url: '/order-revenues-update/' + id ,
        data: formData,
        method: 'put',
    })
}


export default RevenueService

