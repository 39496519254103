import { notification } from 'antd'
import fetch from 'auth/FetchInterceptor'

const ProfileService = {}

ProfileService.FetchEmployeeChangePassword = function (data) {
    return fetch({
        url: '/change/password',
        method: 'post',
        data: data.data,
    })
}

ProfileService.FetchBranch = function () {
    return fetch({
        url: '/branches-list',
        method: 'get',
    })
}

ProfileService.FetchDepartment = function () {
    return fetch({
        url: '/departments-list',
        method: 'get',
    })
}

ProfileService.FetchGroup = function () {
    return fetch({
        url: '/groups-list',
        method: 'get',
    })
}

ProfileService.FetchTaxes = function () {
    return fetch({
        url: '/taxes-list',
        method: 'get',
    })
}

ProfileService.CreateBranch = function (data) {
    return fetch({
        url: '/branch-create',
        method: 'post',
        data: data,
    })
}

ProfileService.CreateDepartment = function (data) {
    return fetch({
        url: '/department-create',
        method: 'post',
        data: data,
    })
}

ProfileService.CreateGroup = function (data) {
    return fetch({
        url: '/group-create',
        method: 'post',
        data: data,
    })
}

ProfileService.CreateTaxes = function (data) {
    return fetch({
        url: '/taxes-create',
        method: 'post',
        data: data,
    })
}

ProfileService.FetchAdmin = function () {
    return fetch({
        url: '/admin/admin-prifile',
        method: 'get',
    })
}

ProfileService.AdminChangePassword = function (data) {
  
    return fetch({
        url: '/admin/change/password',
        method: 'post',
        data: data.data,
    })
}

// group,branch,department

ProfileService.UpdateBranch = function (data) {
    return fetch({
        url: '/branch-update/' + data.branchId,
        method: 'put',
        data: data
    })
}
ProfileService.UpdateDepartment = function (data) {
    return fetch({
        url: '/department-update/' + data.departmentId,
        method: 'put',
        data: data
    })
}
ProfileService.UpdateGroup = function (data) {
    return fetch({
        url: '/group-update/' + data.groupId,
        method: 'put',
        data: data
    })
}
ProfileService.DeleteBranch = function (id) {
    return fetch({
        url: '/branch-delete/' + id.id,
        method: 'delete',
    })
}
ProfileService.DeleteDepartment = function (id) {
    return fetch({
        url: '/department-delete/' + id.id,
        method: 'delete',
    })
}
ProfileService.DeleteGroup = function (id) {
    return fetch({
        url: '/group-delete/' + id.id,
        method: 'delete',
    })
}

// Customer POD Service
ProfileService.FetchCustomerPODView = function () {
    return fetch({
        url: '/pod-list',
        method: 'get',
    })
}
ProfileService.FetchCustomerPODCreate = function (formdata) {
    return fetch({
        url: '/pod-create',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchCustomerPODUpdate = function (formdata) {
    return fetch({
        url: '/pod-update/' + formdata.formdata.podId,
        method: 'put',
        data: formdata.formdata
    })
}
ProfileService.FetchCustomerPODDelete = function (id) {
    return fetch({
        url: '/pod-delete/' + id.id,
        method: 'delete',
    })
}

// Customer type Service
ProfileService.FetchCustomerTypeView = function () {
    return fetch({
        url: '/customer-type-list',
        method: 'get',
    })
}
ProfileService.FetchCustomerTypeCreate = function (formdata) {
    return fetch({
        url: '/customer-type-create',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchCustomerTypeUpdate = function (formdata) {
    return fetch({
        url: '/customer-type-update/' + formdata.formdata.customerId,
        method: 'put',
        data: formdata.formdata
    })
}
ProfileService.FetchCustomerTypeDelete = function (id) {
    return fetch({
        url: '/customer-type-delete/' + id.id,
        method: 'delete',
    })
}

// Vendor type Service
ProfileService.FetchVendorTypeView = function () {
    return fetch({
        url: '/vendor-type-list',
        method: 'get',
    })
}
ProfileService.FetchVendorTypeCreate = function (formdata) {
    return fetch({
        url: '/vendor-type-create',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchVendorTypeUpdate = function (formdata) {
    return fetch({
        url: '/vendor-type-update/' + formdata.formdata.vendorId,
        method: 'put',
        data: formdata.formdata
    })
}
ProfileService.FetchVendorTypeDelete = function (id) {
    return fetch({
        url: '/vendor-type-delete/' + id.id,
        method: 'delete',
    })
}

// document type Service
ProfileService.FetchDocumentTypeView = function () {
    return fetch({
        url: '/document-type-list',
        method: 'get',
    })
}
ProfileService.FetchDocumentTypeCreate = function (formdata) {
    return fetch({
        url: '/document-type-create',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchDocumentTypeUpdate = function (formdata) {
    return fetch({
        url: '/document-type-update/' + formdata.formdata.documentId,
        method: 'put',
        data: formdata.formdata
    })
}
ProfileService.FetchDocumentTypeDelete = function (id) {
    return fetch({
        url: '/document-type-delete/' + id.id,
        method: 'delete',
    })
}

// load type Service
ProfileService.FetchLoadTypeView = function () {
    return fetch({
        url: '/loadtype-list',
        method: 'get',
    })
}
ProfileService.FetchLoadTypeCreate = function (formdata) {

    return fetch({
        url: '/loadtype-create',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchLoadTypeUpdate = function (formdata) {
    return fetch({
        url: '/loadtype-update/'+formdata.formdata.loadId,
        method: 'put',
        data: formdata.formdata
    })
}
ProfileService.FetchLoadTypeDelete = function (id) {
    return fetch({
        url: '/loadtype-delete/' + id.id,
        method: 'delete',
    })
}

// package type Service
ProfileService.FetchPackageTypeView = function () {
    return fetch({
        url: '/package-type-list',
        method: 'get',
    })
}
ProfileService.FetchPackageTypeCreate = function (formdata) {
    return fetch({
        url: '/package-type-create',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchPackageTypeUpdate = function (formdata) {
    return fetch({
        url: '/package-type-update/' + formdata.formdata.packageId,
        method: 'put',
        data: formdata.formdata
    })
}

ProfileService.FetchPackageTypeDelete = function (id) {
    return fetch({
        url: '/package-type-delete/' + id.id,
        method: 'delete',
    })
}

ProfileService.ProfileUpdate = function (formdata) {
    return fetch({
        url: '/company-update',
        method: 'post',
        data: formdata.formdata
    });
}

ProfileService.ProfileView = function () {

    return fetch({
        url: `/company-view`,
        method: 'get',
    })
}

//COMMODITY
ProfileService.FetchCommodityGet= function () {
    return fetch({
        url: '/commodity',
        method: 'get',
    })
}
ProfileService.FetchCommodityCreate = function (formdata) {
    return fetch({
        url: '/commodity',
        method: 'post',
        data: formdata.formdata
    })
}
ProfileService.FetchCommodityUpdate = function (formdata) {
    return fetch({
        url: '/commodity/' + formdata.formdata.commodityId,
        method: 'post',
        data: formdata.formdata
    })
    // .catch((e)=>notification.error(e.error))
}
ProfileService.FetchCommodityDelete = function (id) {
    return fetch({
        url: '/commodity/' + id.id,
        method: 'delete',
    })
}
export default ProfileService

