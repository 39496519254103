import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="page" />}>
            <Switch>
                <Route path={`/superadmin/login`} component={lazy(() => import(`./authentication/super-admin-login`))} />
                <Redirect exact from={`${AUTH_PREFIX_PATH}`} to={`/login`} />
                {/* <Route path={`${AUTH_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} /> */}
                <Route path={`/login`} component={lazy(() => import(`./authentication/driver-login`))} />
                <Route path={`${AUTH_PREFIX_PATH}/driver-login`} component={lazy(() => import(`./authentication/driver-login`))} />
                <Route path={`${AUTH_PREFIX_PATH}/super-admin-login`} component={lazy(() => import(`./authentication/super-admin-login`))} />
                <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
                <Route path={`${AUTH_PREFIX_PATH}/super-admin-forgot-password`} component={lazy(() => import(`./authentication/super-admin-forgot`))} />
                <Route path={`${AUTH_PREFIX_PATH}/reset-password`} component={lazy(() => import(`./authentication/reset-password`))} />
                <Route path={`${AUTH_PREFIX_PATH}/privacy-policy`} component={lazy(() => import(`./privacy-policy`))} />
                <Redirect from={`${AUTH_PREFIX_PATH}`} to={`/login`} />
            </Switch>
        </Suspense>
    )
}

export default AppViews;

