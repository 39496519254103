import fetch from 'auth/FetchInterceptor';

const VendorService = {}

VendorService.FetchVendors = function (paginationParams) {
    return fetch({
        url: `/vendor-list?per_page=${paginationParams.per_page}&page=${paginationParams.page}&search=${paginationParams.search}`,
        method: 'get'
    })
}
VendorService.DeleteVendors = function (id) {
    return fetch({
        url: '/vendor-delete/' + id.id,
        method: 'delete',
    })
}
VendorService.CreateVendors = function (data) {
    return fetch({
        url: '/vendor-create',
        method: 'post',
        data: data
    })
}
VendorService.ViewVendors = function (data) {
    return fetch({
        url: '/vendor-view/' + data.vendorId,
        method: 'get'
    })
}
VendorService.UpdateVendors = function (data) {
    return fetch({
        url: '/vendor-update/' + data.id,
        method: 'put',
        data: data
    })
}

VendorService.UpdateStatusVendors = function (data) {
    return fetch({
        url: '/vendor-status/' + data.id,
        method: 'put',
        data: data
    })
}


VendorService.CreateContactVendors = function (data) {
    return fetch({
        url: '/vendor-contact-create',
        method: 'post',
        data: data
    })
}
VendorService.UpdateContactVendors = function (formData) {
    return fetch({
        url: '/vendor-contact-update/' + formData.updateid,
        method: 'put',
        data: formData.formdata
    })
}
VendorService.DeleteContactVendors = function (id) {
    return fetch({
        url: '/vendor-contact-delete/' + id,
        method: 'delete',
    })
}

VendorService.CreateBtnVendors = function () {

    return null

}
VendorService.SearchVendors = function (data) {
    if (data.data.searchinternal === "internal_name") {
        return fetch({
            url: '/vendor-list?internal_name=' + data.data.filterdata,
            method: 'get'
        })
    }
    if (data.data.searchinternal === "legal_name") {
        return fetch({
            url: '/vendor-list?legal_name=' + data.data.filterdata,
            method: 'get'
        })
    }
}

VendorService.CreateDocumentVendor = function (data) {
    return fetch({
        url: '/vendor-document-create ',
        method: 'post',
        data: data
    })
}
VendorService.UpdateDocumentVendor = function (formData) {
    return fetch({
        url: '/vendor-document-update/' + formData.get('docId'),
        method: 'post',
        data: formData
    })
}

VendorService.ViewVendorDocument = function (payload) {
    return fetch({
        url: '/vendor-document-view/' + payload.documentId,
        method: 'get',
    })
}

VendorService.DeleteDocumentVendor = function (id) {
    return fetch({
        url: '/vendor-document-delete/' + id,
        method: 'delete',
    })
}

VendorService.DeleteUploadedDocumentVendor = function (payload) {
    return fetch({
        url: '/single-document-delete/' + payload.docId,
        method: 'post',
    })
}
export default VendorService

