import { remove } from "lodash";
import * as constant from "../constants/ProfileConstants";

const initialState = {
    loading: false,
    error: null,
    changepassword_success: false,
    data: [],
    branch_list: [],
    department_list: [],
    group_list: [],
    taxes_list: [],
    admin_list: [],
    create_success: false,
    create_branch_success: false,
    create_department_success: false,
    create_group_success: false,
    create_taxes_success: false,
    emp_group_data: null,
    group_data: null,
    department_data: null,
    emp_department_data: null,
    eloading: false,
    update_success: false,
    updatebranch_data: null,
    profile_name: null,
    customerpod_view: null,
    customerpod_create: null,
    customerpod_update: null,
    customerpod_delete: null,

    customertype_view: null,
    customertype_create: null,
    customertype_update: null,
    customertype_delete: null,

    vendortype_view: null,
    vendortype_create: null,
    vendortype_update: null,
    vendortype_delete: null,

    documenttype_view: null,
    documenttype_create: null,
    documenttype_update: null,
    documenttype_delete: null,

    loadtype_view: null,
    loadtype_create: null,
    loadtype_update: null,
    loadtype_delete: null,
    loadview: null,

    packagetype_view: null,
    packagetype_create: null,
    packagetype_update: null,
    packagetype_delete: null,

    //COMMODITY
    commodity_view: null,
    commodity_create: null,
    commodity_update: null,
    commodity_delete: null,
    newcommodity: null,

    profile_update_success: null,
    profile_view_success: null,

    newpod: null,
    newvendor: null,
    newdoc: null,
    newpackage: null,
};

const ProfileReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.EMPLOYEE_CHANGE_PASSWORD:
        case constant.FETCH_BRANCH:
        case constant.FETCH_DEPARTMENT:
        case constant.FETCH_GROUP:
        case constant.FETCH_TAXES:
        case constant.CREATE_BRANCH:
        case constant.CREATE_DEPARTMENT:
        case constant.CREATE_GROUP:
        case constant.CREATE_TAXES:
        case constant.ADMIN_FETCH:
        case constant.ADMIN_CHANGE_PASSWORD:
        case constant.UPDATE_BRANCH:
        case constant.UPDATE_DEPARTMENT:
        case constant.UPDATE_GROUP:
        case constant.DELETE_BRANCH:
        case constant.DELETE_DEPARTMENT:
        case constant.DELETE_GROUP:

        case constant.CUSTOMERPOD_FETCH:
        case constant.CUSTOMERPOD_CREATE:
        case constant.CUSTOMERPOD_UPDATE:
        case constant.CUSTOMERPOD_DELETE:

        case constant.CUSTOMERTYPE_FETCH:
        case constant.CUSTOMERTYPE_CREATE:
        case constant.CUSTOMERTYPE_UPDATE:
        case constant.CUSTOMERTYPE_DELETE:

        case constant.VENDORTYPE_FETCH:
        case constant.VENDORTYPE_CREATE:
        case constant.VENDORTYPE_UPDATE:
        case constant.VENDORTYPE_DELETE:

        case constant.DOCUMENTTYPE_FETCH:
        case constant.DOCUMENTTYPE_CREATE:
        case constant.DOCUMENTTYPE_UPDATE:
        case constant.DOCUMENTTYPE_DELETE:

        case constant.LOADTYPE_FETCH:
        case constant.LOADTYPE_CREATE:
        case constant.LOADTYPE_UPDATE:
        case constant.LOADTYPE_DELETE:

        case constant.PACKAGETYPE_FETCH:
        case constant.PACKAGETYPE_CREATE:
        case constant.PACKAGETYPE_UPDATE:
        case constant.PACKAGETYPE_DELETE:
        case constant.PROFILE_EDIT_INIT:
        case constant.PROFILE_VIEW_INIT:

        case constant.COMMODITY_CREATE:
        case constant.COMMODITY_FETCH:
        case constant.COMMODITY_UPDATE:
        case constant.COMMODITY_DELETE:

            return {
                ...state,
                loading: true,
                changepassword_success: false,
            };

        case constant.EMPLOYEE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                changepassword_success: response,
                changepassworddata: response.data,
            };


        case constant.PROFILE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                profile_update_success: response,
            };

        case constant.PROFILE_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                profile_view_success: response.data,
                profile_name: response.data.name,
            };

        case constant.FETCH_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                branch_list: response.data,
            };
        case constant.FETCH_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department_list: response.data,
            };
        case constant.FETCH_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                group_list: response.data,
            };
        case constant.FETCH_TAXES_SUCCESS:
            return {
                ...state,
                loading: false,
                taxes_list: response.data.data,
            };
        case constant.CREATE_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                branch_data: response.data,
                create_success: response,
            };
        case constant.CREATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department_data: response.data,
                emp_department_data: response,
                create_success: response,
            };
        case constant.CREATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                group_data: response.data,
                emp_group_data: response,
                create_success: response,
            };
        case constant.CREATE_TAXES_SUCCESS:
            return {
                ...state,
                loading: false,
                taxes_data: response.data,
                create_taxes_success: response.status,
            };
        case constant.ADMIN_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                changepassword_success: response,
            };
        case constant.ADMIN_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                admin_list: response.data,
            };


        case constant.UPDATE_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                branch_data: response.data,
                update_success: response,
            };
        case constant.DELETE_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
            };
        case constant.UPDATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department_data: response.data,
                update_success: response,
            };
        case constant.DELETE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
            };
        case constant.UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                group_data: response.data,
                update_success: response,
            };
        case constant.DELETE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
            };

        // Customer Pod 
        case constant.CUSTOMERPOD_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                customerpod_view: response.data,
            };
        case constant.CUSTOMERPOD_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newpod: response.data,
                create_success: response,
                customerpod_create: response,
            };
        case constant.CUSTOMERPOD_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newpod: response.data,
                update_success: response,
                customerpod_update: response,
            };
        case constant.CUSTOMERPOD_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
                customerpod_delete: response,
            };

        // Customer type 
        case constant.CUSTOMERTYPE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                customertype_view: response.data,
            };
        case constant.CUSTOMERTYPE_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                customertype_update: response.data,
                customertype_create: response,
                create_success: response,
            };
        case constant.CUSTOMERTYPE_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                customertype_update: response.data,
                update_success: response,
            };
        case constant.CUSTOMERTYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                customertype_delete: response,
                delete_success: response,
            };

        // vendor type 
        case constant.VENDORTYPE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                vendortype_view: response.data,
            };
        case constant.VENDORTYPE_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newvendor: response.data,
                create_success: response,
                vendortype_create: response,
            };
        case constant.VENDORTYPE_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newvendor: response.data,
                update_success: response,
                vendortype_update: response,
            };
        case constant.VENDORTYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
                vendortype_delete: response,
            };

        // document type 
        case constant.DOCUMENTTYPE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                documenttype_view: response.data,
            };
        case constant.DOCUMENTTYPE_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newdoc: response.data,
                create_success: response,
                documenttype_create: response,
            };
        case constant.DOCUMENTTYPE_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newdoc: response.data,
                documenttype_update: response,
                update_success: response,
            };
        case constant.DOCUMENTTYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
                documenttype_delete: response,
            };

        // load type 
        case constant.LOADTYPE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                loadtype_view: response,
                loadview: response.data.data,
            };
        case constant.LOADTYPE_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadtype_create: response,
                newload: response.data,
                create_success: response,
            };
        case constant.LOADTYPE_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadtype_update: response,
                newload: response.data,
                update_success: response,
            };
        case constant.LOADTYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadtype_delete: response,
                delete_success: response,
            };

        // package type 
        case constant.PACKAGETYPE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                packagetype_view: response.data,
            };
        case constant.PACKAGETYPE_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newpackage: response.data,
                packagetype_create: response,
                create_success: response,
            };
        case constant.PACKAGETYPE_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newpackage: response.data,
                packagetype_update: response,
                update_success: response,
            };
        case constant.PACKAGETYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
                packagetype_delete: response,
            };
        // COMMODITY
        case constant.COMMODITY_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                commodity_view: response.data,
            };
        case constant.COMMODITY_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newcommodity: response.data,
                commodity_create: response,
                create_success: response,
            };
        case constant.COMMODITY_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                commodity_update: response,
                newcommodity: response.data,
                update_success: response,
            };
        case constant.COMMODITY_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
                commodity_delete: response,
            };

        case constant.EMPLOYEE_CHANGE_PASSWORD_ERROR:
        case constant.FETCH_BRANCH_ERROR:
        case constant.FETCH_DEPARTMENT_ERROR:
        case constant.FETCH_GROUP_ERROR:
        case constant.FETCH_TAXES_ERROR:
        case constant.CREATE_BRANCH_ERROR:
        case constant.CREATE_DEPARTMENT_ERROR:
        case constant.CREATE_GROUP_ERROR:
        case constant.CREATE_TAXES_ERROR:
        case constant.ADMIN_CHANGE_PASSWORD_ERROR:
        case constant.ADMIN_FETCH_ERROR:
        case constant.UPDATE_BRANCH_ERROR:
        case constant.UPDATE_DEPARTMENT_ERROR:
        case constant.UPDATE_GROUP_ERROR:
        case constant.DELETE_BRANCH_ERROR:
        case constant.DELETE_DEPARTMENT_ERROR:
        case constant.DELETE_GROUP_ERROR:

        case constant.CUSTOMERPOD_FETCH_ERROR:
        case constant.CUSTOMERPOD_CREATE_ERROR:
        case constant.CUSTOMERPOD_UPDATE_ERROR:
        case constant.CUSTOMERPOD_DELETE_ERROR:
        case constant.CUSTOMERTYPE_FETCH_ERROR:
        case constant.CUSTOMERTYPE_CREATE_ERROR:
        case constant.CUSTOMERTYPE_UPDATE_ERROR:
        case constant.CUSTOMERTYPE_DELETE_ERROR:
        case constant.VENDORTYPE_FETCH_ERROR:
        case constant.VENDORTYPE_CREATE_ERROR:
        case constant.VENDORTYPE_UPDATE_ERROR:
        case constant.VENDORTYPE_DELETE_ERROR:
        case constant.DOCUMENTTYPE_FETCH_ERROR:
        case constant.DOCUMENTTYPE_CREATE_ERROR:
        case constant.DOCUMENTTYPE_UPDATE_ERROR:
        case constant.DOCUMENTTYPE_DELETE_ERROR:
        case constant.LOADTYPE_FETCH_ERROR:
        case constant.LOADTYPE_CREATE_ERROR:
        case constant.LOADTYPE_UPDATE_ERROR:
        case constant.LOADTYPE_DELETE_ERROR:
        case constant.PACKAGETYPE_FETCH_ERROR:
        case constant.PACKAGETYPE_CREATE_ERROR:
        case constant.PACKAGETYPE_UPDATE_ERROR:
        case constant.PACKAGETYPE_DELETE_ERROR:
        case constant.PROFILE_EDIT_ERROR:

        //COMMODITY
        case constant.COMMODITY_CREATE_ERROR:
        case constant.COMMODITY_UPDATE_ERROR:
        case constant.COMMODITY_DELETE_ERROR:
        case constant.COMMODITY_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};
export default ProfileReducer;