import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/EmployeeConstants";
import EmployeeService from "../../services/EmployeeService";

export function* FetchEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.FetchEmployees, payload);
        yield put({ type: constant.FETCH_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.FETCH_EMPLOYEES_ERROR, error });
    }
}
export function* CreateEmployeeSaga(payload) {

    try {
        const response = yield call(EmployeeService.CreateEmployees, payload.data);
        yield put({ type: constant.CREATE_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATE_EMPLOYEES_ERROR, error });
    }
}
export function* UpdateEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.UpdateEmployees, payload?.data);
        yield put({ type: constant.UPDATE_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.UPDATE_EMPLOYEES_ERROR, error });
    }
}
export function* UpdateEmployeeStatusSaga(payload) {
    try {
        const response = yield call(EmployeeService.UpdateEmployeesStatus, payload.formData);
        yield put({ type: constant.UPDATE_EMPLOYEE_STATUS_SUCCESS , response });
    } catch (error) {
        yield put({ type: constant.UPDATE_EMPLOYEE_STATUS_ERROR, error });
    }
}
export function* ViewEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.ViewEmployees, payload.id);
        yield put({ type: constant.VIEW_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.VIEW_EMPLOYEES_ERROR, error });
    }
}
export function* ViewEmployeeDocument(payload) {
    try {
        const response = yield call(EmployeeService.ViewEmployeeDocument, payload);
        yield put({ type: constant.EMPLYOEE_DOCUMET_VIEW_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.EMPLYOEE_DOCUMET_VIEW_ERROR, error });
    }
}
export function* DeleteEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.DeleteEmployees, payload.id);
        yield put({ type: constant.DELETE_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.DELETE_EMPLOYEES_ERROR, error });
    }
}
export function* createdocumentemployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.CreateDocumentEmployees, payload.data);
        yield put({ type: constant.CREATE_DOCUMENTEMPLOYEES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_DOCUMENTEMPLOYEES_ERROR, error })
    }
}
export function* DeleteUploadedDocSaga(payload) {
    try {
        const response = yield call(EmployeeService.DeleteUploadedDocument, payload);
        yield put({ type: constant.EMPLYOEE_UPLOADED_DOCUMET_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.EMPLYOEE_UPLOADED_DOCUMET_DELETE_ERROR, error })
    }
}
export function* updatedocumentemployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.UpdateDocumentEmployees, payload.formData);
        yield put({ type: constant.UPDATE_DOCUMENTEMPLOYEES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_DOCUMENTEMPLOYEES_ERROR, error })
    }
}
export function* deletedocumentemployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.DeleteDocumentEmployees, payload.id);
        yield put({ type: constant.DELETE_DOCUMENTEMPLOYEES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_DOCUMENTEMPLOYEES_ERROR, error })
    }
}
export function* FetchEmployeeBtnSaga() {
    try {
        const response = yield call(EmployeeService.CreateBtnEmployees);
        yield put({ type: constant.CREATEBTN_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATEBTN_EMPLOYEES_ERROR, error });
    }
}
export function* searchEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.SearchEmployees, payload);
        yield put({ type: constant.SEARCH_EMPLOYEES_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.SEARCH_EMPLOYEES_ERROR, error })
    }
}
export function* FetchExpiryEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.FetchExpiryEmployees, payload);
        yield put({ type: constant.FETCH_EXPIRY_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.FETCH_EXPIRY_EMPLOYEES_ERROR, error });
    }
}
export function* CreateUpdateExpiryEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.CreateUpdateExpiryEmployees, payload.data);
        yield put({ type: constant.CREATEUPDATE_EXPIRY_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATEUPDATE_EXPIRY_EMPLOYEES_ERROR, error });
    }
}
export function* ViewExpiryEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.ViewExpiryEmployees, payload.id);
        yield put({ type: constant.VIEW_EXPIRY_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.VIEW_EXPIRY_EMPLOYEES_ERROR, error });
    }
}
export function* FetchNotifyEmployeeSaga(payload) {
    try {
        const response = yield call(EmployeeService.FetchNotifyEmployees, payload);
        yield put({ type: constant.FETCH_NOTIFY_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.FETCH_NOTIFY_EMPLOYEES_ERROR, error });
    }
}

export function* logoutdeviceSaga(payload) {
    try {
        const response = yield call(EmployeeService.LogoutDevice, payload);
        yield put({ type: constant.LOGOUT_DEVICE_EMPLOYEES_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.LOGOUT_DEVICE_EMPLOYEES_ERROR, error });
    }
}
export default function* EmployeeSaga() {
    yield takeLatest(constant.FETCH_EMPLOYEES, FetchEmployeeSaga);
    yield takeLatest(constant.CREATE_EMPLOYEES, CreateEmployeeSaga);
    yield takeLatest(constant.UPDATE_EMPLOYEES, UpdateEmployeeSaga);
    yield takeLatest(constant.VIEW_EMPLOYEES, ViewEmployeeSaga);
    yield takeLatest(constant.EMPLYOEE_DOCUMET_VIEW_INIT, ViewEmployeeDocument);
    yield takeLatest(constant.DELETE_EMPLOYEES, DeleteEmployeeSaga);
    yield takeLatest(constant.EMPLYOEE_UPLOADED_DOCUMET_DELETE_INIT, DeleteUploadedDocSaga);
    yield takeLatest(constant.CREATE_DOCUMENTEMPLOYEES, createdocumentemployeeSaga);
    yield takeLatest(constant.UPDATE_DOCUMENTEMPLOYEES, updatedocumentemployeeSaga);
    yield takeLatest(constant.DELETE_DOCUMENTEMPLOYEES, deletedocumentemployeeSaga);
    yield takeLatest(constant.FETCH_EMPLOYEES, FetchEmployeeBtnSaga);
    yield takeLatest(constant.SEARCH_EMPLOYEES, searchEmployeeSaga);
    yield takeLatest(constant.FETCH_EXPIRY_EMPLOYEES, FetchExpiryEmployeeSaga);
    yield takeLatest(constant.CREATEUPDATE_EXPIRY_EMPLOYEES, CreateUpdateExpiryEmployeeSaga);
    yield takeLatest(constant.VIEW_EXPIRY_EMPLOYEES, ViewExpiryEmployeeSaga);
    yield takeLatest(constant.FETCH_NOTIFY_EMPLOYEES, FetchNotifyEmployeeSaga);
    yield takeLatest(constant.LOGOUT_DEVICE_EMPLOYEES_INIT, logoutdeviceSaga);
    yield takeLatest(constant.UPDATE_EMPLOYEE_STATUS, UpdateEmployeeStatusSaga);
}
