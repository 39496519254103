import fetch from 'auth/FetchInterceptor';

const EmployeeService = {}

EmployeeService.FetchEmployees = function (paginationParams) {

    return fetch({
        url: `/employee-list?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
        method: 'get',
    })
}
EmployeeService.ViewEmployees = function (id) {

    return fetch({
        url: `/employee-view/${id}`,
        method: 'get',

    })
}
EmployeeService.DeleteEmployees = function (id) {

    return fetch({
        url: `/employee-delete/${id}`,
        method: 'delete',

    })
}

EmployeeService.CreateEmployees = function (formData) {
    return fetch({
        headers: {
            "content-type": 'multipart/form-data'
        },
        url: '/employee-create',
        method: 'post',
        data: formData

    })
}
EmployeeService.DeleteUploadedDocument = function (payload) {
    return fetch({
        url: '/document-delete/' + payload.docId,
        method: 'post',
    })
}
EmployeeService.UpdateEmployees = function (data) {

    return fetch({
        url: '/employee-update/' + data?.id,
        method: 'post',
        data: data?.formData
    })
}
EmployeeService.UpdateEmployeesStatus = function (formData) {
    return fetch({
        url: '/employee-status/' + formData.id,
        method: 'put',
        data: formData,

    })
}
EmployeeService.CreateDocumentEmployees = function (data) {
    return fetch({
        url: '/employee-document-create',
        method: 'post',
        data: data
    })
}
EmployeeService.UpdateDocumentEmployees = function (formData) {
    return fetch({
        url: '/employee-document-update/' + formData.get('docId'),
        method: 'post',
        data: formData
    })
}

EmployeeService.ViewEmployeeDocument = function (payload) {
    return fetch({
        url: '/employee-document-view/' + payload.documentId,
        method: 'get',
    })
}

EmployeeService.DeleteDocumentEmployees = function (id) {
    return fetch({
        url: '/employee-document-delete/' + id,
        method: 'delete',
    })
}
EmployeeService.CreateBtnEmployees = function () {

    return null

}
EmployeeService.SearchEmployees = function (data) {
    if (data.data.searchinternal === "internal_name") {
        return fetch({
            url: '/employee-list?internal_name=' + data.data.filterdata,
            method: 'get'
        })
    }
    if (data.data.searchinternal === "first_name") {
        return fetch({
            url: '/employee-list?first_name=' + data.data.filterdata,
            method: 'get'
        })
    }
    if (data.data.searchinternal === "email") {
        return fetch({
            url: '/employee-list?email=' + data.data.filterdata,
            method: 'get'
        })
    }
    if (data.data.searchinternal === "phone") {
        return fetch({
            url: '/employee-list?phone=' + data.data.filterdata,
            method: 'get'
        })
    }
}

EmployeeService.CreateUpdateExpiryEmployees = function (data) {
    return fetch({
        url: '/employee-expiry-update/' + data.id,
        method: 'post',
        data: data
    })
}
EmployeeService.FetchExpiryEmployees = function (paginationParams) {

    return fetch({
        //url:'/driver-expiry-lists',
        url: `/driver-expiry-lists?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
        method: 'get',
    })
}

EmployeeService.FetchNotifyEmployees = function () {

    return fetch({
        url: `/driver-expiry-limit`,
        method: 'get',
    })
}
EmployeeService.ViewExpiryEmployees = function (id) {
   
    return fetch({
        // url: `/driver-expiry-view/${id}`,
        url: `/employee-view/${id}`,
        method: 'get',

    })
}
EmployeeService.LogoutDevice = function (data) {

    return fetch({
        // url: `/driver-expiry-view/${id}`,
        url: `/driver/logout/${data.id}`,
        method: 'post',

    })
}

export default EmployeeService
