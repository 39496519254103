import * as constant from "../constants/ShipmentConstants";

const initialState = {
    shipment_list: [],
   shipment_view: null,
    loading: false,
    error: null,
};

const ShipmentReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_SHIPMENT_INIT:
        case constant.VIEW_SHIPMENT_INIT:
            return {
                ...state,
                loading: true
            };

        case constant.FETCH_SHIPMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                shipment_list: response.data
            };
        
        case constant.VIEW_SHIPMENT_SUCCESS:
            return {
                    ...state,
                    loading: false,
                    shipment_view: response.data
            };
        

        case constant.FETCH_SHIPMENT_ERROR:
        case constant.VIEW_SHIPMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: response,
            };

        default:
            return state;
    }
};

export default ShipmentReducer;
