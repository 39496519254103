export const SERVICEFETCH_EQUIPMENTS = 'SERVICEFETCH_EQUIPMENTS'; 
export const SERVICEFETCH_EQUIPMENTS_SUCCESS = 'SERVICEFETCH_EQUIPMENTS_SUCCESS';
export const SERVICEFETCH_EQUIPMENTS_ERROR = 'SERVICEFETCH_EQUIPMENTS_ERROR';

export const SERVICECREATE_EQUIPMENTS = 'SERVICECREATE_EQUIPMENTS'; 
export const SERVICECREATE_EQUIPMENTS_SUCCESS = 'SERVICECREATE_EQUIPMENTS_SUCCESS';
export const SERVICECREATE_EQUIPMENTS_ERROR = 'SERVICECREATE_EQUIPMENTS_ERROR';

export const VIEWSERVICE_EQUIPMENTS = 'VIEWSERVICE_EQUIPMENTS'; 
export const VIEWSERVICE_EQUIPMENTS_SUCCESS = 'VIEWSERVICE_EQUIPMENTS_SUCCESS';
export const VIEWSERVICE_EQUIPMENTS_ERROR = 'VIEWSERVICE_EQUIPMENTS_ERROR';