import fetch from "auth/FetchInterceptor";

const CustomerService = {};

CustomerService.FetchCustomers = function (paginationParams) {
  return fetch({
    url: `/customer-list?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
    method: "get",
  });
};

CustomerService.FetchCountry = function (data) {
  return fetch({
    url: "/essential-list?select=" + data,
    method: "get",
    data: data,
  });
};

CustomerService.ViewCustomers = function (data) {
  return fetch({
    url: "/customer-view/" + data.id,
    method: "get",
  });
};

CustomerService.CreateCustomers = function (data) {
  return fetch({
    url: "/customer-create",
    method: "post",
    data: data,
  });
};

CustomerService.UpdateCustomers = function (data) {
  return fetch({
    url: "/customer-update/" + data.id,
    method: "post",
    data: data.formData,
  });
};

CustomerService.UpdateCustomersStatus = function (data) {
  return fetch({
    url: "/customer-status/" + data.id,
    method: "put",
    data: data,
  });
};

CustomerService.DeleteCustomers = function (id) {
  return fetch({
    url: "/customer-delete/" + id.id,
    method: "delete",
  });
};

CustomerService.CreateCustomercontact = function (data) {
  return fetch({
    url: "/customer-contact-create",
    method: "post",
    data: data,
  });
};

CustomerService.UpdateCustomercontact = function (data) {
  return fetch({
    url: "/customer-contact-update/" + data.updateid,
    method: "put",
    data: data.contactformdata,
  });
};

CustomerService.CreateCustomerdocument = function (data) {
  return fetch({
    url: "/customer-document-create",
    method: "post",
    data: data,
  });
};

CustomerService.DeleteCustomercontact = function (id) {
  return fetch({
    url: "/customer-contact-delete/" + id.id,
    method: "delete",
  });
};

CustomerService.DeleteCustomerUploadedDoc = function (id) {
  return fetch({
    url: "/customer-document-destroy/" + id.id,
    method: "post",
  });
};

CustomerService.UpdateCustomerdocument = function (formData) {
  return fetch({
    url: "/customer-document-update/" + formData.get("id"),
    method: "post",
    data: formData,
  });
};

CustomerService.DeleteCustomerdocument = function (id) {
  return fetch({
    url: "/customer-document-delete/" + id.id,
    method: "delete",
  });
};

CustomerService.CreateBtnCustomers = function () {
  return null;
};

CustomerService.SearchCustomers = function (data) {
  if (data.data.searchinternal === "internal_name") {
    return fetch({
      // url: '/customer-list?search='+data.data,
      url: "/customer-list?internal_name=" + data.data.filterdata,
      method: "get",
    });
  }
  if (data.data.searchinternal === "legal_name") {
    return fetch({
      url: "/customer-list?legal_name=" + data.data.filterdata,
      method: "get",
    });
  }
  if (data.data.searchinternal === "email") {
    return fetch({
      url: "/customer-list?email=" + data.data.filterdata,
      method: "get",
    });
  }
};
CustomerService.FetchExpiryCustomers = function (paginationParams) {
  return fetch({
    url: `/customer-expiry-list?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
    method: "get",
  });
};
CustomerService.CreateUpdateExpiryCustomers = function (data) {
  return fetch({
    url: "/customer-expiry-update/" + data.id,
    method: "post",
    data: data,
  });
};
CustomerService.ViewExpiryCustomers = function (id) {
  return fetch({
    url: "/customer-view/" + id,
    method: "get",
  });
};
CustomerService.StatusCustomers = function (data) {
  return fetch({
    url: "/customer-limit-status/" + data.customer_id,
    method: "post",
    data: data,
  });
};
export default CustomerService;
