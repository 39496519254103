import * as constant from "../constants/VendorConstants";

const initialState = {
    success: false,
    vendor_list: [],
    single_vendor: null,
    loading: false,
    error: null,
    data: null,
    create_success: false,
    update_success: false,
    new_contact: [],
    delete_success: null,
    created: null,
    updated: null,
    deleted: null,
    update_status_success: null,
    document_created:null,
    emp_document_data:null,
    uploaded_doc_deleted:null,
    delete_doc_success:null,
    update_emp_doc:null,

};

const VendorReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.FETCH_VENDORS:
        case constant.DELETE_VENDORS:
        case constant.CREATE_VENDORS:
        case constant.VIEW_VENDORS:
        case constant.UPDATE_VENDORS:
        case constant.UPDATE_VENDORS_STATUS:
        case constant.CREATE_CONTACTVENDORS:
        case constant.UPDATE_CONTACTVENDORS:
        case constant.DELETE_CONTACTVENDORS:
        case constant.CREATE_DOCUMENTVENDOR:
        case constant.UPDATE_DOCUMENTVENDOR:
        case constant.DELETE_DOCUMENTVENDOR:
        case constant.VENDOR_DOCUMET_VIEW_INIT:
        case constant.VENDOR_UPLOADED_DOCUMET_DELETE_INIT:
            return {
                ...state,
                loading: true,
                create_success: false,
                update_success: false
            };
        case constant.FETCH_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                vendor_list: response.data,
            };

        case constant.CREATE_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                create_success: response.status,
            };

        case constant.DELETE_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                delete_success: response,
            };

        case constant.VIEW_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_vendor: response.data
            };

        case constant.UPDATE_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                update_success: response.status
            };

        case constant.UPDATE_VENDORS_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                update_status_success: response
            };
        case constant.CREATE_CONTACTVENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                new_contact: response.data,
                created: response
            };
        case constant.UPDATE_CONTACTVENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                new_contact: response.data,
                updated: response
            };
        case constant.DELETE_CONTACTVENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                deleted: response
            };
        case constant.CREATEBTN_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                single_vendor: null,
            };
        case constant.SEARCH_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                vendor_list: response.data,
            };
            case constant.CREATE_DOCUMENTVENDOR_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    new_document: response.data,
                    document_created: response
                };
            case constant.UPDATE_DOCUMENTVENDOR_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    update_emp_doc: response.data,
                };
            case constant.DELETE_DOCUMENTVENDOR_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    delete_doc_success: response
                };
            case constant.VENDOR_UPLOADED_DOCUMET_DELETE_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        uploaded_doc_deleted: response
                    };
            case constant.VENDOR_DOCUMET_VIEW_SUCCESS:
                        return {
                            ...state,
                            loading: false,
                            emp_document_data: response,
                        };
            
        case constant.FETCH_VENDORS_ERROR:
        case constant.DELETE_VENDORS_ERROR:
        case constant.CREATE_VENDORS_ERROR:
        case constant.VIEW_VENDORS_ERROR:
        case constant.UPDATE_VENDORS_ERROR:
        case constant.UPDATE_VENDORS_STATUS_ERROR:
        case constant.CREATE_CONTACTVENDORS_ERROR:
        case constant.UPDATE_CONTACTVENDORS_ERROR:
        case constant.DELETE_CONTACTVENDORS_ERROR:
        case constant.CREATE_DOCUMENTVENDOR_ERROR:
        case constant.UPDATE_DOCUMENTVENDOR_ERROR:
        case constant.DELETE_DOCUMENTVENDOR_ERROR:
        case constant.VENDOR_DOCUMET_VIEW_ERROR:
        case constant.VENDOR_UPLOADED_DOCUMET_DELETE_ERROR:
       
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
};

export default VendorReducer;
