import { notification } from 'antd';
import fetch from 'auth/FetchInterceptor';

const QuoteService = {}

QuoteService.FetchQuotes = function (paginationParams) {
    return fetch({
        url: `/quotes-list?status=${paginationParams.data.status}&per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
        method: 'get'
    })
}
QuoteService.FetchQuotesCounts = function () {
    return fetch({
        url: `/quotes-status-count`,
        method: 'get'
    })
}
QuoteService.CreateQuote = function (formData) {
    return fetch({
        url: '/quotes-create',
        method: 'post',
        data: formData
    })
}
QuoteService.UploadPODDoc = function (formData) {
    const url = formData.get('endPoint');
    formData.delete('endPoint');
    return fetch({
        url: url,
        method: 'post',
        data: formData
    });
}
QuoteService.UpdateQuote = function (formData) {
    return fetch({
        url: `/quotes-update/${formData?.formData?.quoteId}`,
        method: 'post',
        data: formData?.formDatas12
    })
}
QuoteService.ViewQuote = function (data) {
    return fetch({
        url: '/quotes-view/' + data.quoteId,
        method: 'get'
    })
}
QuoteService.DeleteQuote = function (quotationId) {
    return fetch({
        url: '/quotes-delete/' + quotationId.quoteId,
        method: 'delete',
    })
}

QuoteService.DeletePOD = function (payload) {
    return fetch({
        url: payload.podId.endPoint,
        method: 'delete',
    });
}

QuoteService.MoveQuote = function (quotationId) {
    return fetch({
        url: '/quotes-order-create/' + quotationId,
        method: 'post',
        data: {}
    })
}

QuoteService.MoveQuoteAgain = function (quotationId, formData) {
    return fetch({
        url: '/quotes-order-move/' + quotationId,
        method: 'post',
        data: formData
    })
}

QuoteService.QuoteMailSend = function (quotationId) {
    console.log(quotationId)
    try {
        

    let endPoint  =""
    let method  = ""
    if(quotationId.type=="bill"){
        endPoint = "/billing/mail/"
    }
    if(quotationId.type=="invoice"){
        endPoint = '/quote-statement-mail/'

    }
    return fetch({
        url: '/quote-statement-mail/' + quotationId?.id,
        method: 'post',
        data: quotationId?.values
    }).then((data)=>{notification.success({message:data?.message}); quotationId?.setEmailPopup(false)}).catch(()=>{})
}

catch (error) {
        console.log(error)
}
}
export default QuoteService

