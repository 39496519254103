import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/VendorConstants";
import VendorService from "../../services/VendorService";

export function* fetchvendorSaga(payload) {
    try {
        const response = yield call(VendorService.FetchVendors, payload.data);
        yield put({ type: constant.FETCH_VENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.FETCH_VENDORS_ERROR, error })
    }
}
export function* deletevendorSaga(payload) {
    try {
        const response = yield call(VendorService.DeleteVendors, payload);
        yield put({ type: constant.DELETE_VENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_VENDORS_ERROR, error })
    }
}
export function* createvendorSaga(payload) {
    try {
        const response = yield call(VendorService.CreateVendors, payload.data);
        yield put({ type: constant.CREATE_VENDORS_SUCCESS, response })
    } catch (error) {

        yield put({ type: constant.CREATE_VENDORS_ERROR, error })
    }
}
export function* viewvendorSaga(vendorId) {
    try {
        const response = yield call(VendorService.ViewVendors, vendorId);
        yield put({ type: constant.VIEW_VENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VIEW_VENDORS_ERROR, error })
    }
}
export function* updatevendorSaga(payload) {
    try {
        const response = yield call(VendorService.UpdateVendors, payload.data);
        yield put({ type: constant.UPDATE_VENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_VENDORS_ERROR, error })
    }
}

export function* updatestatusvendorSaga(payload) {
    try {
        const response = yield call(VendorService.UpdateStatusVendors, payload.data);
        yield put({ type: constant.UPDATE_VENDORS_STATUS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_VENDORS_STATUS_ERROR, error })
    }
}

export function* createcontactvendorSaga(payload) {
    try {
        const response = yield call(VendorService.CreateContactVendors, payload.data);
        yield put({ type: constant.CREATE_CONTACTVENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_CONTACTVENDORS_ERROR, error })
    }
}
export function* updatecontactvendorSaga(payload) {
    try {
        const response = yield call(VendorService.UpdateContactVendors, payload.formData);
        yield put({ type: constant.UPDATE_CONTACTVENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_CONTACTVENDORS_ERROR, error })
    }
}
export function* deletecontactvendorSaga(payload) {
    try {
        const response = yield call(VendorService.DeleteContactVendors, payload.id);
        yield put({ type: constant.DELETE_CONTACTVENDORS_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_CONTACTVENDORS_ERROR, error })
    }
}
export function* FetchVendorBtnSaga() {
    try {
        const response = yield call(VendorService.CreateBtnVendors);
        yield put({ type: constant.CREATEBTN_VENDORS_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.CREATEBTN_VENDORS_ERROR, error });
    }
}
export function* searchVendorSaga(payload) {
    try {
        const response = yield call(VendorService.SearchVendors, payload);
        yield put({ type: constant.SEARCH_VENDORS_SUCCESS, response })

    } catch (error) {
        yield put({ type: constant.SEARCH_VENDORS_ERROR, error })
    }
}

export function* ViewVendorDocumentSaga(payload) {
    try {
        const response = yield call(VendorService.ViewVendorDocument, payload);
        yield put({ type: constant.VENDOR_DOCUMET_VIEW_SUCCESS, response });
    } catch (error) {
        yield put({ type: constant.VENDOR_DOCUMET_VIEW_ERROR, error });
    }
}

export function* createdocumentvendorSaga(payload) {
    try {
        const response = yield call(VendorService.CreateDocumentVendor, payload.data);
        yield put({ type: constant.CREATE_DOCUMENTVENDOR_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.CREATE_DOCUMENTVENDOR_ERROR, error })
    }
}
export function* DeleteUploadedDocVendorSaga(payload) {
    try {
        const response = yield call(VendorService.DeleteUploadedDocumentVendor, payload);
        yield put({ type: constant.VENDOR_UPLOADED_DOCUMET_DELETE_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.VENDOR_UPLOADED_DOCUMET_DELETE_ERROR, error })
    }
}
export function* updatedocumentVendorSaga(payload) {
    try {
        const response = yield call(VendorService.UpdateDocumentVendor, payload.formData);
        yield put({ type: constant.UPDATE_DOCUMENTVENDOR_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.UPDATE_DOCUMENTVENDOR_ERROR, error })
    }
}
export function* deletedocumentVendorSaga(payload) {
    try {
        const response = yield call(VendorService.DeleteDocumentVendor, payload.id);
        yield put({ type: constant.DELETE_DOCUMENTVENDOR_SUCCESS, response })
    } catch (error) {
        yield put({ type: constant.DELETE_DOCUMENTVENDOR_ERROR, error })
    }
}

export default function* VendorSaga() {
    yield takeLatest(constant.FETCH_VENDORS, fetchvendorSaga);
    yield takeLatest(constant.DELETE_VENDORS, deletevendorSaga);
    yield takeLatest(constant.CREATE_VENDORS, createvendorSaga);
    yield takeLatest(constant.UPDATE_VENDORS, updatevendorSaga);
    yield takeLatest(constant.VIEW_VENDORS, viewvendorSaga);
    yield takeLatest(constant.CREATE_CONTACTVENDORS, createcontactvendorSaga);
    yield takeLatest(constant.UPDATE_CONTACTVENDORS, updatecontactvendorSaga);
    yield takeLatest(constant.DELETE_CONTACTVENDORS, deletecontactvendorSaga);
    yield takeLatest(constant.FETCH_VENDORS, FetchVendorBtnSaga);
    yield takeLatest(constant.SEARCH_VENDORS, searchVendorSaga);
    yield takeLatest(constant.UPDATE_VENDORS_STATUS,updatestatusvendorSaga);
    yield takeLatest(constant.VENDOR_DOCUMET_VIEW_INIT,ViewVendorDocumentSaga);
    yield takeLatest(constant.CREATE_DOCUMENTVENDOR,createdocumentvendorSaga);
    yield takeLatest(constant.VENDOR_UPLOADED_DOCUMET_DELETE_INIT,DeleteUploadedDocVendorSaga);
    yield takeLatest(constant.UPDATE_DOCUMENTVENDOR,updatedocumentVendorSaga);
    yield takeLatest(constant.DELETE_DOCUMENTVENDOR,deletedocumentVendorSaga);
}