import {
    FETCH_EMPLOYEES,
    DELETE_EMPLOYEES,
    CREATE_EMPLOYEES,
    VIEW_EMPLOYEES,
    UPDATE_EMPLOYEES,
    UPDATE_EMPLOYEE_STATUS,
    CREATE_DOCUMENTEMPLOYEES,
    UPDATE_DOCUMENTEMPLOYEES,
    DELETE_DOCUMENTEMPLOYEES,
    CREATEBTN_EMPLOYEES,
    SEARCH_EMPLOYEES,
    EMPLYOEE_DOCUMET_VIEW_INIT,
    EMPLYOEE_UPLOADED_DOCUMET_DELETE_INIT,
    FETCH_EXPIRY_EMPLOYEES,
    VIEW_EXPIRY_EMPLOYEES,
    CREATEUPDATE_EXPIRY_EMPLOYEES,
    FETCH_NOTIFY_EMPLOYEES,
    LOGOUT_DEVICE_EMPLOYEES_INIT,
} from '../constants/EmployeeConstants';

export const FetchEmployees = (paginationParams) => {
    return {
        type: FETCH_EMPLOYEES,
        data: paginationParams
    }
};

export const CreateEmployees = (data) => {

    return {
        type: CREATE_EMPLOYEES,
        data,
    }
};
export const ViewEmployees = (id) => {
    return {
        type: VIEW_EMPLOYEES,
        id,
    }
};
export const DeleteEmployees = (id) => {
    return {
        type: DELETE_EMPLOYEES,
        id,
    }
};

export const UpdateEmployees = (formData, id) => {

    const data = {
        formData: formData,
        id: id
    }
    return {
        type: UPDATE_EMPLOYEES,
        data,
    }
};

export const UpdateEmployeesStatus = (formData) => {
    return {
        type: UPDATE_EMPLOYEE_STATUS,
        formData,
    }
};

export const CreateDocumentEmployees = (data) => {
    return {
        type: CREATE_DOCUMENTEMPLOYEES,
        data,
    }
}

export const UpdateDocumentEmployees = (formData) => {
    return {
        type: UPDATE_DOCUMENTEMPLOYEES,
        formData,
    }
}

export const DeleteDocumentEmployees = (id) => {
    return {
        type: DELETE_DOCUMENTEMPLOYEES,
        id
    }
}

export const CreateBtnEmployees = () => {
    return {
        type: CREATEBTN_EMPLOYEES,
    }
}

export const SearchEmployees = (data) => {
    return {
        type: SEARCH_EMPLOYEES,
        data,
    }
}

export const ViewEmpDocument = (documentId) => {
    return {
        type: EMPLYOEE_DOCUMET_VIEW_INIT,
        documentId
    }
}

export const DeleteUploadedDocument = (docId) => {
    return {
        type: EMPLYOEE_UPLOADED_DOCUMET_DELETE_INIT,
        docId,
    }
}

export const FetchExpiryEmployees = (paginationParams) => {
    return {
        type: FETCH_EXPIRY_EMPLOYEES,
        data: paginationParams
    }
};

export const CreateUpdateExpiryEmployees = (data) => {
    return {
        type: CREATEUPDATE_EXPIRY_EMPLOYEES,
        data,
    }
};
export const ViewExpiryEmployees = (id) => {
    return {
        type: VIEW_EXPIRY_EMPLOYEES,
        id,
    }
};

export const FetchNotification = (paginationParams) => {
    return {
        type: FETCH_NOTIFY_EMPLOYEES,
    }

};
export const LogoutDevices = (id) => {
    return {
        type: LOGOUT_DEVICE_EMPLOYEES_INIT,
        id,
    }
};